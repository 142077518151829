import styled from 'styled-components'

const StyledList = styled.ul`
  
`

const WrapItem = styled.li`
  direction: flex;
  width: 100%;
  
  &.item {
    &:first-child {
      a {
        border-top: 1px solid ${props => props.theme.colors.gray10};
      }
    }

    a {
      border-bottom: 1px solid ${props => props.theme.colors.gray10};
      ${props => props.theme.fullSize};
      color: ${props => props.theme.colors.gray90};
      display: block;
      padding-top: 19px;
      padding-bottom: 19px;
      width: calc(100% + 36px);

      &:hover {
        background-color: ${props => props.theme.colors.lYellow};
      }
    }

    i {
      margin-right: -8px;
    }
  }

  p {
    margin-bottom: ${props => `${props.textDiff}px`};
  }
`

const StyledItem = styled.div`
  ${props => props.theme.dFlexBetween};
`

export { StyledList, WrapItem, StyledItem };