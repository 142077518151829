import get from 'lodash/get'
import { createSelector } from 'reselect'

export const getFoodsItems = createSelector(
  (state) => get(state, ['foods', 'content']) || [],
  (content) => content,
)

export const getFoodsItem = createSelector(
  (state) => get(state, ['foods', 'currentItem']) || [],
  (currentItem) => currentItem,
)

export const getFoodsFilteredId = createSelector(
  (state) => get(state, ['foods', 'filtered']) || [],
  (filtered) => filtered,
)

export const getFoodsContent = createSelector(
  (state) => get(state, ['foods', 'content']) || {},
  (state) => get(state, ['foods', 'filtered']) || [],
  (state) => get(state, ['foods', 'content', 'items_section', 'list']) || [],
  (content, filtered, list) => {
    return {
      ...content,
      items_section: {
        ...content.items_section,
        list: filtered.length ? list.filter(el => filtered.includes(el.item_category_id)) : list
      }
    }
  },
)

export const getFoodsActivePage = createSelector(
  (state) => get(state, ['foods', 'activePage']) || [],
  (activePage) => activePage,
)