import styled from 'styled-components'

const StyledLayout = styled.div`
  padding-top: 59px;
  padding-bottom: 70px;
`

const ComponentMain = styled.main`
  ${props => props.theme.container};
  padding-bottom: 20px;
`

export { StyledLayout, ComponentMain };