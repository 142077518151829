import React from 'react'
import PropTypes from 'prop-types'

import Typography from '../Typography'

import { Component } from './styles'

export default function Date({ children, ...rest }) {
  return (
    <Component {...rest}>
      <Typography tag={'span'}>{children}</Typography>
    </Component>
  )
}

Date.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, 
    PropTypes.string
  ]).isRequired,
}

