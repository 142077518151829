import { put, takeLatest } from 'redux-saga/effects'

import TYPES from 'store/types'
import actionsHome from 'store/home/actions'
import { http, endpoints } from 'helpers/http'

function* fetchHomePage() {
  try {
    const response = yield http.get(`${endpoints.base}home`)

    if (response.success) {
      yield put(actionsHome.homeSuccess(response))
    } else {
      yield put(actionsHome.homeFailure())
    }
  } catch(error) {
    yield put(actionsHome.homeFailure())
  }
}

function* actionWatcherHome() {
  yield takeLatest(TYPES.HOME.GET_PAGE_PENDING, fetchHomePage)
}

export { actionWatcherHome }
