import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Helper } from 'helpers/simple'

import actionsApp from 'store/app/actions'
import { getLayout } from 'store/app/selectors'

export const usePageActions = () => {
  const dispatch = useDispatch()
  const data = useSelector(getLayout)
  const history = useHistory()

  useEffect(() => {
    if (!Helper.hasObject(data)) {
      ;(() => dispatch(actionsApp.layoutPending()))()
    }
  }, [dispatch, data])

  const onSearch = (e, val) => {
    dispatch(actionsApp.searchPending(val, history))
    dispatch(actionsApp.searchSetActivePage(1))
    
    e.preventDefault()
  }

  return { data, onSearch }
}

  
