import { put, takeLatest } from 'redux-saga/effects'

import TYPES from 'store/types'
import actionsAccount from 'store/account/actions'
import { http, endpoints } from 'helpers/http'

function* fetchAccountPage() {
  try {
    const response = yield http.get(`${endpoints.base}account`)

    if (response.success) {
      yield put(actionsAccount.accountSuccess(response))
    } else {
      yield put(actionsAccount.accountFailure())
    }
  } catch(error) {
    yield put(actionsAccount.accountFailure())
  }
}

function* actionWatcherAccount() {
  yield takeLatest(TYPES.ACCOUNT.GET_PAGE_PENDING, fetchAccountPage)
}

export { actionWatcherAccount }
