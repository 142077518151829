// import routes from 'routes/routes'
import TYPES from 'store/types'

const { CONTACT } = TYPES

const initialState = {
  //currentRoute: routes.examples,
  loading: false,
  content: {},
  errors: {},
  success: {}
}

const handlers = {
  [CONTACT.GET_PAGE_PENDING]: (state) => ({
    ...state,
    loading: true,
    errors: {},
    success: {}
  }),

  [CONTACT.GET_PAGE_SUCCESS]: (state, payload) => ({
    ...state,
    loading: false,
    content: payload
  }),

  [CONTACT.GET_PAGE_FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),

  [CONTACT.POST_PAGE_PENDING]: (state) => ({
    ...state,
    loading: true,
    errors: {},
    success: {}
  }),

  [CONTACT.POST_PAGE_SUCCESS]: (state, payload) => ({
    ...state,
    loading: false,
    success: payload,
    errors: {}
  }),

  [CONTACT.POST_PAGE_FAILURE]: (state, payload) => ({
    ...state,
    loading: false,
    errors: payload,
    success: {}
  }),

  default: (state) => state,
}

const reducerContact = (state = initialState, { type, payload }) => {
  const handler = handlers[type] || handlers.default
  return handler(state, payload)
}

export default reducerContact
