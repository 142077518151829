import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Helper} from 'helpers/simple'
import { pageObj } from 'helpers/postActivities'

export const usePageActions = (match) => {
  const dispatch = useDispatch()
  const listPage = Helper.getCategoryPage(match)
  const data = useSelector(pageObj[listPage].content)
  const id = useSelector(pageObj[listPage].id)
  const activePage = useSelector(pageObj[listPage].activePage)

  useEffect(() => {
    if (pageObj[listPage]) {
      ;(() => dispatch(pageObj[listPage].actionItems()))()
    }
  }, [dispatch, listPage])

  const onCategoryClick = (id) => {
    dispatch(pageObj[listPage].onCategoryClick(id))
    dispatch(pageObj[listPage].onPagingClick(1))
  } 

  const onPagingClick = (page) => {
    dispatch(pageObj[listPage].onPagingClick(page))
    Helper.scrollTop()
  }

  return { 
    data, 
    id, 
    onCategoryClick, 
    onPagingClick, 
    activePage 
  }
}
