import React from 'react'
import PropTypes from 'prop-types'

import Typography from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'

import { StyledItem, StyledIcon, StyledItemBody } from './styles'

export default function ListItem({ icon, title, text, variant }) {
  return (
    <StyledItem>
      <StyledIcon variant={variant}>
        {icon && <Icon name={icon} />}
      </StyledIcon>
      <StyledItemBody>
        {title && <Typography tag={'h3'}>{title}</Typography>}
        {text && <Typography>{text}</Typography>}
      </StyledItemBody>
    </StyledItem>
  )
}

ListItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string
}

ListItem.defaultProps = {
  icon: '',
  title: '',
  text: '',
  variant: 'dot'
}