import TYPES from 'store/types'

const actionsApp = {
  redirect: (payload) => ({
    type: TYPES.APP.REDIRECT,
    payload,
  }),

  layoutPending: () => ({
    type: TYPES.LAYOUT.GET_ALL_PENDING,
  }),

  layoutSuccess: (payload) => ({
    type: TYPES.LAYOUT.GET_ALL_SUCCESS,
    payload,
  }),

  layoutFailure: () => ({
    type: TYPES.LAYOUT.GET_ALL_FAILURE,
  }),

  searchPending: (param, history) => ({
    type: TYPES.SEARCH.GET_ALL_PENDING,
    param,
    history
  }),

  searchSuccess: (payload) => ({
    type: TYPES.SEARCH.GET_ALL_SUCCESS,
    payload,
  }),

  searchFailure: () => ({
    type: TYPES.SEARCH.GET_ALL_FAILURE,
  }),

  searchSetActivePage: (payload) => ({
    type: TYPES.SEARCH.SET_ACTIVE_PAGE,
    payload
  }),
}

export default actionsApp
