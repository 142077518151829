import React from 'react'
import PropTypes from 'prop-types'

import List from 'components/organisms/List';

import { StyledBlock, StyledTitle, StyledHeading } from './styles'


export default function SetingsSection({ 
  content, 
  ...rest 
}) {
  const { title, list } = content
  return (
    <StyledBlock {...rest}>
      {title && <StyledHeading>
        <StyledTitle tag={'h2'}>{title}</StyledTitle>
      </StyledHeading>}
      {list && <List arr={list} settings /> }
    </StyledBlock>
  )
}

SetingsSection.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object)
  }),
}

SetingsSection.defaultProps = {
  content: {
    title: '',
    list: []
  }
}