import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/atoms/Icon'
import Input from 'components/atoms/Input'

import { ComponentBar, IconSearch } from './styles'

export default function SearchBar({ placeholder, onSearch, ...rest }) {
  const [ search, setSearch ] = useState('')
  const onChange = (e) => {
    setSearch(e.target.value)
  }

  return (
    <ComponentBar {...rest}>
      <form onSubmit={(e) => onSearch(e, search)}>
        <Input 
          placeholder={placeholder} 
          variant={'black'} 
          onChange={onChange}
        />
        <IconSearch type={'submit'}>
          <Icon name={'search'} color={'yellow'} fontSize={'19'} />
        </IconSearch>
      </form>
    </ComponentBar>
  )
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func
}

SearchBar.defaultProps = {
  placeholder: 'What are you looking for?'
}