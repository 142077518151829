import React from 'react'

import PostContent from 'components/organisms/PostContent'
import SetingsSection from 'components/templates/SettingsSection'

import { usePageActions } from './hooks'

import styled from 'styled-components'

const StyledPageContent = styled.div`
  ${props => props.theme.fullSize};
  background-color: ${props => props.theme.colors.lYellow};
  padding-top: 30px;
  padding-bottom: 2px;

  h1 {
    margin-bottom: 10px;
  }

  h1 + div {
    padding-top: 0;
  }
`

const StyledAccount = styled.div`
  padding-top: 4px;
`

export default function Account() {
  const { data } = usePageActions()

  return (
    <>
      {(data.item_title || data.item_content) && (
        <StyledPageContent>
          <PostContent 
            title={data.item_title} 
            content={data.item_content} 
          />
        </StyledPageContent>
      )}
      <StyledAccount>
        {data.link_sections && (
          data.link_sections.map((item) => (
            <SetingsSection 
              key={item.key}
              content={item}
            />
            )
          ))}
      </StyledAccount>
    </>
  );
}
