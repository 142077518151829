import React, { useState } from 'react'
import PropTypes from 'prop-types'

import LinkItem from 'components/atoms/Link'
import Image from 'components/atoms/Image'
import MenuList from 'components/organisms/MenuList'

import { ROUTERS_PATH } from 'constants/routers'

import {
  StyledMenu,
  StyledDropMenu,
  StyledLogo,
  StyledDropTop,
  StyledDropBottom
} from './styles'
import { Helper } from 'helpers/simple'

export default function Menu({ content, logo }) {
  const { navMenu, mainMenu, addMenu } = content

  const [isMenuOpen, setMenuOpen] = useState(false)
  const onMenuClick = (isOpen) => setMenuOpen(isOpen)

  Helper.overflowBody(isMenuOpen)

  return (
    <StyledMenu>
      {navMenu && (
        <MenuList 
          menu={navMenu}
          onMenuClick={onMenuClick}
          isMenuOpen={isMenuOpen}
          line
        />
      )}
      {isMenuOpen && (
        <StyledDropMenu>
          <StyledDropTop>
            <StyledLogo>
              <LinkItem to={ROUTERS_PATH.HOME}>
                <Image params={logo} />
              </LinkItem>
            </StyledLogo>
            {mainMenu && (
              <MenuList 
                menu={mainMenu}
                onMenuClick={onMenuClick}
                isMenuOpen={isMenuOpen}
              />
            )}
          </StyledDropTop>
          <StyledDropBottom>
            {addMenu && (
              <MenuList 
                menu={addMenu}
                onMenuClick={onMenuClick}
                isMenuOpen={isMenuOpen}
              />
            )}
          </StyledDropBottom>
        </StyledDropMenu>
      )}
    </StyledMenu>
  )
}

Menu.propTypes = {
  content: PropTypes.shape({
    navMenu: PropTypes.array,
    mainMenu: PropTypes.array,
    addMenu: PropTypes.array
  }),
  logo: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  })
}

Menu.defaultProps = {
  content: PropTypes.shape({
    navMenu: [],
    mainMenu: [],
    addMenu: []
  }),
  logo: {}
}