import TYPES from 'store/types'

const actionsFoods = {
  foodsPending: () => ({
    type: TYPES.FOODS.GET_ALL_PENDING,
  }),

  foodsSuccess: (payload) => ({
    type: TYPES.FOODS.GET_ALL_SUCCESS,
    payload,
  }),

  foodsFailure: () => ({
    type: TYPES.FOODS.GET_ALL_FAILURE,
  }),

  foodsSinglePending: (id) => ({
    type: TYPES.FOODS.GET_BY_ID_PENDING,
    id
  }),

  foodsSingleSuccess: (payload) => ({
    type: TYPES.FOODS.GET_BY_ID_SUCCESS,
    payload,
  }),

  foodsSingleFailure: () => ({
    type: TYPES.FOODS.GET_BY_ID_FAILURE,
  }),

  foodsFiltered: (payload) => ({
    type: TYPES.FOODS.GET_FILTERED,
    payload
  }),

  foodsSetActivePage: (payload) => ({
    type: TYPES.FOODS.SET_ACTIVE_PAGE,
    payload
  })
}

export default actionsFoods
