import React from 'react'
import PropTypes from 'prop-types'
import Component from './styles'

export default function Typography({ 
  children, 
  tag, 
  variant, 
  ...rest 
}) {
  return <Component {...rest} as={tag} variant={variant || tag}>{children}</Component>
}

Typography.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, 
    PropTypes.string
  ]),
  tag: PropTypes.string,
  variant: PropTypes.string,
}

Typography.defaultProps = {
  tag: 'p'
}
