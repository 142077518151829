import React from 'react'
import PropTypes from 'prop-types'

import LinkItem from 'components/atoms/Link'
import Typography from 'components/atoms/Typography'

import { Helper } from 'helpers/simple'

import { StyledAddress, StyledAdd, StyledAddressText } from './styles'

export default function AddressBlock({ content, ...rest }) {
  const {
    title,
    address,
    phone_label,
    phone,
    email_label,
    email
  } = content

  return (
    <StyledAddress {...rest}>
      {title && <Typography tag={'h3'}>{title}</Typography>}
      {address && <StyledAddressText dangerouslySetInnerHTML={{ __html: address }} />}
      {phone && <StyledAdd>
        {phone_label && <Typography tag={'span'}>{phone_label}</Typography>}
        {phone && (
          <LinkItem 
            to={`tel:${Helper.phoneNormalize(phone)}`}
            notLink
          >{phone}
          </LinkItem>
        )}
      </StyledAdd>}
      {email && <StyledAdd>
        {email_label && <Typography tag={'span'}>{email_label}</Typography>}
        <LinkItem 
            to={`mailto:${email}`}
            notLink
          >{email}
          </LinkItem>
      </StyledAdd>}
    </StyledAddress>
  )
}

AddressBlock.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    address: PropTypes.string,
    phone_label: PropTypes.string,
    phone: PropTypes.string,
    email_label: PropTypes.string,
    email: PropTypes.string
  }),
}

AddressBlock.defaultProps = {
  content: {
    title: '',
    address: '',
    phone_label: '',
    phone: '',
    email_label: '',
    email: ''
  }
}
