import styled from 'styled-components'
import Typography from 'components/atoms/Typography'

const StyledBlock = styled.div`
  ${props => props.theme.fullSize};
  background: ${props => props.theme.colors[props.bg]};
  position: relative;
  padding-top: 20px;
  padding-bottom: ${props => props.bg ? '20px' : '10px'};
  margin-bottom: ${props => props.bg ? '8px' : '15px'};

  &:before {
    background: ${props => props.theme.colors[props.borderBottom]};
    content: '';
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const StyledHeading = styled.div`
  ${props => props.theme.dFlexAlignCenter};
  display: flex;
  padding-bottom: 14px;

  img {
    width: auto;
    margin-right: 15px;
  }
`

const StyledTitle = styled(Typography)`
  margin: 0;
  max-width: 60%;

  &:only-child {
    max-width: 100%;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
`

const StyledText = styled(Typography)`
  margin-bottom: 20px;

  div + & {
    margin-top: -10px;
  }
`

export {
  StyledBlock,
  StyledHeading,
  StyledTitle,
  StyledText
};