import styled from 'styled-components'

const StyledSlider = styled.div`
  ${props => props.theme.fullSize};
  overflow: hidden;
  margin-bottom: 16px;
  padding: 0;

  .swiper-slide {
    display: flex;
    position: relative;
    min-height: 538px;

    img {
      width: 100%;
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: 40%;
    left: 18px;
    text-align: left;

    span {
      ${props => props.theme.sliderkDots};
      ${props => props.theme.sliderDotButton};
    }
  }
`
const StyledSlydeContent = styled.div`
  ${props => props.theme.container};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${props => props.theme.colors.white};
`

const StyledImage = styled.div`
  ${props => props.theme.fullAbsolute};
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;

  &::before {
    background-image: linear-gradient(to top, ${props => props.theme.colors.black} 0%, rgba(0, 0, 0, 0) 100%);
    ${props => props.theme.fullAbsolute};
    content: '';
  }
`

const StyledTextBlock = styled.div`
  position: relative;

  h1 {
    font-size: 40px;
    max-height: 96px;
    overflow: hidden;
  }
`

const StyledTextBottom = styled.div`
  ${props => props.theme.dFlexBetween};
  padding-bottom: 25px;
  line-height: 1.6;

  p  {
    max-height: 50px;
    overflow: hidden;
    margin-bottom: 0;
  }

  i {
    transform: translate(3px, -5px) scaleX(-1);
    display: block;
  }
`

export { 
  StyledSlider,
  StyledSlydeContent,
  StyledImage,
  StyledTextBlock,
  StyledTextBottom
};