import axios from 'axios'
import { Helper } from 'helpers/simple'
import { NAME_STORAGE } from 'constants/nameStorage'
import { ROUTERS_PATH } from 'constants/routers'

const http = axios.create({
  baseURL: `${window.baseUrl ? window.baseUrl : process.env.REACT_APP_API_END_POINT}/wp-json/`
})

const endpoints = {
  base: 'theme-sugar-hive/v1/',
  token: 'jwt-auth/v1/'
}

http.interceptors.request.use((config) => {
  if (!(config.url.includes('token') 
      || config.url.includes('login') 
      || config.url.includes('main')
    )) {
    config.headers = {
      ...config.headers, 
      Authorization: `Bearer ${Helper.getItem(NAME_STORAGE.TOKEN)}`,
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

http.interceptors.response.use((response) => {
  if (!response.data.success) {
    if (response.data.statusCode === 401 
       || response.data.statusCode === 403) {
        Helper.clearItem(NAME_STORAGE.TOKEN)
        if (Helper.getLocation().pathname !== ROUTERS_PATH.LOGIN) {
          Helper.push(ROUTERS_PATH.LOGIN)
        }
    }
    if (response.data.statusCode === 404) {
      Helper.push(ROUTERS_PATH.NOT_FOUND)
    }
  }
  return response.data;
}, (error) => {
  return Promise.reject(error);
});



export { http, endpoints }