import React from 'react'
import PropTypes from 'prop-types'

import Badge from 'components/atoms/Badge'

import { StyledTitle, StyledContent, StyledCategory, StylesList, StyledItem } from './styles'

export default function CategoryBlock({
  content, 
  onClick,
  filtered,
  ...rest 
}) {
  const { title, list } = content

  return (
    <StyledContent {...rest}>
      {title && <StyledTitle tag={'h3'}>{title}</StyledTitle>}
      <StyledCategory>
        <StylesList>
          {list && (
            list.map((item) => (
              <StyledItem key={item.key}>
                <Badge 
                  onClick={() => onClick(item.category_id)}
                  active={filtered.indexOf(item.category_id) > -1}
                >
                  {item.label}
                </Badge>
              </StyledItem>
            ))
          )}
        </StylesList>  
      </StyledCategory>
    </StyledContent>
  )
}

CategoryBlock.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.array
  }),
  filtered: PropTypes.array,
  onClick: PropTypes.func
}

CategoryBlock.defaultProps = {
  content: {},
  filtered: []
}
