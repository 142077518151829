import get from 'lodash/get'
import { createSelector } from 'reselect'

export const getLoginPage = createSelector(
  (state) => get(state, ['login', 'content']) || [],
  (content) => content,
)

export const getLoginSuccess = createSelector(
  (state) => get(state, ['login', 'success']) || [],
  (success) => success,
)

export const getLoginError = createSelector(
  (state) => get(state, ['login', 'errors']) || [],
  (errors) => errors,
)