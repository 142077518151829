import React from "react";
import PropTypes from 'prop-types'

import AccordionItem from 'components/molecules/AccordionItem'

import { StyledAccordion } from './styles'

export default function AccordionBlock({ list }) {
  return (
    <StyledAccordion>
      {list.map((item) => (
        <AccordionItem 
          key={item.key}
          item={item}
        />
      ))}
    </StyledAccordion>
  )
}

AccordionBlock.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    item: PropTypes.shape({
      opener: PropTypes.string,
      text: PropTypes.string,
    })
  })),
}

AccordionBlock.defaultProps = {
  list: []
}
