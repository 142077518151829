// import routes from 'routes/routes'
import TYPES from 'store/types'

const { ACCOUNT } = TYPES

const initialState = {
  //currentRoute: routes.examples,
  loading: false,
  content: {},
}

const handlers = {
  [ACCOUNT.GET_PAGE_PENDING]: (state) => ({
    ...state,
    loading: true
  }),

  [ACCOUNT.GET_PAGE_SUCCESS]: (state, payload) => ({
    ...state,
    loading: false,
    content: payload
  }),

  [ACCOUNT.GET_PAGE_FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),

  default: (state) => state,
}

const reducerAccount = (state = initialState, { type, payload }) => {
  const handler = handlers[type] || handlers.default
  return handler(state, payload)
}

export default reducerAccount
