import React from 'react'
import PropTypes from 'prop-types'

import LinkItem from 'components/atoms/Link'
import Icon from 'components/atoms/Icon'
import ListItem from 'components/molecules/ListItem'

import { StyledList, WrapItem, StyledItem } from './styles'

export default function LatestList({ 
  arr, 
  variant, 
  textDiff, 
  settings, 
  ...rest 
}) {
  return (
    <StyledList {...rest} >
      {arr.map((item) => (
        <WrapItem 
          key={item.key} 
          textDiff={textDiff}
          className={settings ? 'item' : ''}
        >
          {!settings ? (<ListItem 
            icon={item.icon_text}
            variant={variant}
            title={item.title}
            text={item.description || item.text}
          />) : (
            <LinkItem 
              to={item.url}
              target={item.target}
            >
              <StyledItem>
                {item.title}
                <Icon name={'circle-arrow'} />
              </StyledItem>
            </LinkItem>
          )}
        </WrapItem>
      ))}
    </StyledList>
  )
}

LatestList.propTypes = {
  arr: PropTypes.arrayOf(PropTypes.object).isRequired,
  variant: PropTypes.string,
  textDiff: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  settings: PropTypes.bool
}

LatestList.defaultProps = {
  variant: '',
  textDiff: 15,
  settings: false
}
