import './App.css';

import ProviderTheme from 'themes/providerTheme'

import Routes from 'routes';

function App() {
  return (
    <ProviderTheme>
      <Routes />
    </ProviderTheme>
  );
}

export default App;
