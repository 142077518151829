import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import LinkItem from 'components/atoms/Link'
import MenuLink from 'components/molecules/MenuLink'
import Logout from 'components/molecules/Logout'

import { Helper } from 'helpers/simple'

import { StyledItem } from './styles'

export default function MenuItem({
  item,
  inline,
  isMenuOpen,
  onMenuClick,
  ...rest
}) {
  const {
    url,
    text,
    icon,
    logout
  } = item

  return (
    <StyledItem className={inline ? 'inline' : ''} {...rest}>
      {!logout ? (url !== '#' ? (
        !Helper.isRefLink(url) ? (
          <NavLink 
            //exact={url !== '/' ? false : true} 
            exact
            to={url}
            className={isMenuOpen ? 'not-active' : ''}
            onClick={() => onMenuClick(false)}
          >
            <MenuLink icon={icon} text={text} />
          </NavLink>
        ) : (
          <LinkItem to={url} target={'_blank'}>
            <MenuLink icon={icon} text={text} />
          </LinkItem>
        )
      ) : (
        <MenuLink 
          className={`menu ${isMenuOpen ? 'active' : ''}`}
          onClick={() => onMenuClick(true)}
          icon={icon} 
          text={text}
          iconFont={20}
        />
      )) : (
        <Logout>
          <MenuLink icon={icon} text={text} />
        </Logout>
      )}
    </StyledItem>
  )
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    logout: PropTypes.bool
  }),
  inline: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
  onMenuClick: PropTypes.func
}

MenuItem.defaultProps = {
  item: {
    url: '',
    text: '',
    icon: 'logo',
    logout: false
  },
  inline: false,
  isMenuOpen: false,
}