import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import actionsFaq from 'store/faq/actions'
import { getFaqPage } from 'store/faq/selectors'

export const usePageActions = () => {
  const dispatch = useDispatch()
  const data = useSelector(getFaqPage)

  useEffect(() => {
    ;(() => dispatch(actionsFaq.faqPending()))()
  }, [dispatch])

  return { data }
}

  
