import React from 'react'
import PropTypes from 'prop-types'

import Typography from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Date from 'components/atoms/Date'

import {
  StyledItem,
  StyledItemBody,
  StyledImage,
  StyledCategory,
  StyledDate
} from './styles'

export default function LatestItem({ item, ...rest }) {
  const {
    item_date,
    item_id,
    item_image,
    item_title,
    item_category,
    item_type 
  } = item 
  
  return (
    <StyledItem to={`/${item_type}/${item_id}`} {...rest}>
      {item_image?.src && (
        <StyledImage>
          <Image params={item_image} />
        </StyledImage>
      )}
      <StyledItemBody>
        {item_category && <StyledCategory tag={'h3'}>{item_category}</StyledCategory>}
        {item_title && <Typography>{item_title}</Typography>}
        {item_date && <StyledDate><Date>{item_date}</Date></StyledDate>}
      </StyledItemBody>
    </StyledItem>
  )
}

LatestItem.propTypes = {
  item: PropTypes.shape({
    item_date: PropTypes.string,
    item_id: PropTypes.oneOfType([
      PropTypes.number, 
      PropTypes.string
    ]),
    item_image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
    item_title: PropTypes.string,
    item_category: PropTypes.string,
    item_type: PropTypes.string,
  })
}

LatestItem.propDefault = {
  item: {
    item_date: '',
    item_id: '',
    item_image: {
      src: '',
      alt: ''
    },
    item_title: '',
    item_category: '',
    item_type: '',
  }
}