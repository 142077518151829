import { put, takeLatest } from 'redux-saga/effects'

import TYPES from 'store/types'
import actionsContact from 'store/contact/actions'
import { http, endpoints } from 'helpers/http'

function* fetchContactPage() {
  try {
    const response = yield http.get(`${endpoints.base}contact`)

    if (response.success) {
      yield put(actionsContact.contactSuccess(response))
    } else {
      if (response.statusCode === 401) {
        //redirect
      }
      yield put(actionsContact.contactFailure())
    }
  } catch(error) {
    yield put(actionsContact.contactFailure())
  }
}

function* actionWatcherContact() {
  yield takeLatest(TYPES.CONTACT.GET_PAGE_PENDING, fetchContactPage)
}

function* postContactPage({ data }) {
  try {
    const response = yield http.post(`${endpoints.base}contact`, data)
    
    if (response.success) {
      yield put(actionsContact.contactSentSuccess(response))
    } else {
      yield put(actionsContact.contactSentFailure(response))
    }

  } catch(error) {
    yield put(actionsContact.contactSentFailure())
  }
}

function* actionSenderContact() {
  yield takeLatest(TYPES.CONTACT.POST_PAGE_PENDING, postContactPage)
}

export { actionWatcherContact, actionSenderContact }
