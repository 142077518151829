import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { Helper } from 'helpers/simple'
import { NAME_STORAGE } from 'constants/nameStorage'
import { ROUTERS_PATH } from 'constants/routers'

import { StyledLogout } from './styles'

export default function Logout({ children, ...rest }) {
  const history = useHistory()

  const onLogout = () => {
    Helper.clearItem(NAME_STORAGE.TOKEN)
    history.push(ROUTERS_PATH.LOGIN)
  }

  return (
    <StyledLogout {...rest} onClick={onLogout}>{children}</StyledLogout>
  )
}

Logout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, 
    PropTypes.string
  ]).isRequired,
}
