import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import actionsLogin from 'store/login/actions'
import { getLoginPage, getLoginSuccess, getLoginError } from 'store/login/selectors'

export const usePageActions = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const data = useSelector(getLoginPage)
  const dataError = useSelector(getLoginError)
  const dataSuccess = useSelector(getLoginSuccess)
  let checked = false

  useEffect(() => {
    ;(() => dispatch(actionsLogin.loginPending()))()
  }, [dispatch])

  const onRemember = (e) => {
    checked = e.target.checked
  }

  const handleClick = (values) => {
    const arr = []
    for (let key in values) {
      arr.push(key + '=' + values[key])
    }

    const obj = {
      values: arr.join('&'),
      checked
    }

    dispatch(actionsLogin.loginSentPending(obj, history))
  }

  return { data, handleClick, dataError, dataSuccess, onRemember }
}

  
