import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import Header from 'components/organisms/Header'
import Menu from 'components/templates/Menu'

import { usePageActions } from './hooks'

import { StyledLayout, ComponentMain } from './styles'

function Layout({ children, ...rest }) {
  const { data, onSearch } = usePageActions()

  return (
    <StyledLayout {...rest}>
      {data.header && <Header 
        content={data.header} 
        logo={data.logoSmall}
        onSearch={onSearch}
      />}
      <ComponentMain>{children}</ComponentMain>
      {data.navigtion && <Menu 
        content={data.navigtion} 
        logo={data.logo} 
      />}
    </StyledLayout>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, 
    PropTypes.string
  ]).isRequired,
}

export default withRouter(Layout)