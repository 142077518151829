import styled from 'styled-components'

const Component = styled.span`
  ${props => props.theme.badge[props.variant]};
  white-space: nowrap;

  &.active {
    ${props => props.theme.badge.active};
  }
`

export default Component;