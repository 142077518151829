import React from 'react'
import PropTypes from 'prop-types'

import { Formik, Form } from 'formik';

import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import Typography from 'components/atoms/Typography'

import { VALIDATION_SCHEMA } from './scheme'
import { Helper } from 'helpers/simple'

import { StyledForm, StyledField, StyledMessage, StyledButtonWrap, StyledLinkButton, StyledRemembering, StyledForgot, StyledForgotLink } from './styles'

export default function DefaultForm({ 
  content,
  handleClick,
  dataError,
  dataSuccess,
  schema,
  onRemember,
  ...rest 
}) {
  const { list, submit, register_link, remember, forgot  } = content 

  const isError = Helper.hasObject(dataError)
  const isSuccess = Helper.hasObject(dataSuccess)
  const errorsObj = isError && dataError.data
  const userData = schema === 'LOGIN' ? Helper.getItem('user') : ''

  return (
    <StyledForm {...rest}>
      <Formik
       initialValues={userData || Helper.getInitalObj(list, remember)}
       validationSchema={VALIDATION_SCHEMA[schema]}
       onSubmit={handleClick}
     >
       {({ errors, touched, handleSubmit }) => (
         <Form autoComplete={'off'}>
            {isError && dataError.message && (
              <StyledMessage>{dataError.message}</StyledMessage>
            )}
            {isSuccess && dataSuccess.message && (
              <StyledMessage className={'success'}>
                {dataSuccess.message}
              </StyledMessage>
            )}
            {list.map((item, i) => (
              <StyledField key={item.key}>
                <Input 
                  defaultValue={userData && userData[item.name]}
                  placeholder={item.placeholder}
                  type={item.type}
                  name={item.name}
                  label={item.label}
                  required={item.required}
                  id={item.name + i}
                  isFormik={true}
                  error={errors[item.name] || errorsObj[item.name]}
                  touched={touched[item.name]}
                />
              </StyledField>
            ))}
            <StyledButtonWrap>
              <Button 
                onClick={handleSubmit}
                type={submit.type}
              >
                {submit.text}
              </Button>
              {register_link && (
                <StyledLinkButton 
                  to={register_link.url}
                  target={register_link.target}
                >
                  {register_link.text}
                </StyledLinkButton>
              )}
            </StyledButtonWrap>
            {remember && (
              <StyledRemembering>
                <Input 
                  defaultValue={userData && userData[remember.name]}
                  type={remember.type}
                  id={remember.name}
                  name={remember.name}
                  label={remember.label}
                  isFormik={true}
                  error={errors[remember.name] || errorsObj[remember.name]}
                  touched={touched[remember.name]}
                  onClick={onRemember}
                />
              </StyledRemembering>
            )}
            {forgot && (
              <StyledForgot>
                <Typography tag={'span'}>{forgot.text}</Typography>
                <StyledForgotLink 
                  to={forgot.link.url}
                  target={forgot.link.target}
                >
                  {forgot.link.text}
                </StyledForgotLink>
              </StyledForgot>
            )}
         </Form>
       )}
     </Formik>
    </StyledForm>
  )
}

DefaultForm.propTypes = {
  content: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    submit: PropTypes.object
  }),
  schema: PropTypes.string
}

DefaultForm.defaultProps = {
  content: {
    submit: {
      type: 'submit',
      text: 'Submit'
    }
  },
  schema: 'LOGIN'
}