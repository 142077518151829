import { all } from 'redux-saga/effects'

import { actionWatcherLayout, actionWatcherSearch } from './app/saga'
import { actionWatcherNewsById, actionWatcherNewsAll } from './news/saga'
import { actionWatcherOffersById, actionWatcherOffersAll } from './offers/saga'
import { actionWatcherEventsById, actionWatcherEventsAll } from './events/saga'
import { actionWatcherFoodsById, actionWatcherFoodsAll } from './foods/saga'
import { actionWatcherLifestyleById, actionWatcherLifestyleAll } from './lifestyle/saga'
import { actionWatcherContact, actionSenderContact } from './contact/saga'
import { actionWatcherLogin, actionSenderLogin } from './login/saga'
import { actionWatcherAccount } from './account/saga'
import { actionWatcherFaq } from './faq/saga'
import { actionWatcherHome } from './home/saga'

function* rootSaga() {
  yield all([actionWatcherNewsById(), 
            actionWatcherNewsAll(), 
            actionWatcherOffersById(), 
            actionWatcherOffersAll(),
            actionWatcherEventsById(),
            actionWatcherEventsAll(),
            actionWatcherFoodsById(),
            actionWatcherFoodsAll(),
            actionWatcherLifestyleById(),
            actionWatcherLifestyleAll(),
            actionWatcherAccount(),
            actionWatcherContact(),
            actionSenderContact(),
            actionWatcherFaq(),
            actionWatcherLogin(),
            actionSenderLogin(),
            actionWatcherHome(),
            actionWatcherLayout(),
            actionWatcherSearch()
          ])
}

export default rootSaga
