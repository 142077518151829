import styled from 'styled-components'

const StyledVoucher = styled.div`
  ${props => props.theme.fullSize};
  background: ${props => props.theme.colors.lYellow};
  margin-top: -14px;
  margin-bottom: 12px;
`

const StyledHeading = styled.div`
  ${props => props.theme.fullSize};
  background: ${props => props.theme.colors.yellow};
  position: relative;
  overflow: visible;
  padding-top: 16px;
  padding-bottom: 16px;

  &:before {
    background: ${props => props.theme.colors.yellow};

    content: '';
    width: 51px;
    height: 51px;
    transform: rotate(45deg);
    position: absolute;
    left: 20px;
    bottom: -20px;
  }

  h2 {
    position: relative;
    font-size: ${props => props.theme.fontSize.big};
  }
`
const StyledBox = styled.div`
  ${props => props.theme.dFlexAlignCenter};
  padding-top: 26px;
  padding-bottom: 26px;
`

const StyledText = styled.div`
  padding-right: 20px;
  
  h1 {
    margin-bottom: 0;
  }

  strong {
    font-size: ${props => props.theme.fontSize.small};
  }
`

export { StyledVoucher, StyledHeading, StyledBox, StyledText }