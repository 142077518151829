import styled from 'styled-components'

const StyledItem = styled.div`
  ${props => props.theme.fullSize};
  border-bottom: 1px solid ${props => props.theme.colors.gray20};
  padding-right: 28px;
  padding-top: 20px;
  padding-bottom: 17px;

  &:first-child {
    border-top: 1px solid ${props => props.theme.colors.gray20};
  }
`

const StyledOpener = styled.div`
  ${props => props.theme.dFlexBetween};

  &.is-open {
    i {
      transform: rotate(180deg);
    }
  }

  i {
    transition: transform 0.3s;
  }
`

const StyledOpenerText = styled.span`
  max-width: 90%;
`

const StyledSlide = styled.div`
  padding-top: 20px;

  p {
    margin: 0;
  }
`

export { StyledItem, StyledOpener, StyledOpenerText, StyledSlide }