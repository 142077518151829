import React from 'react'
import PropTypes from 'prop-types'

import Typography from 'components/atoms/Typography'
import { Field } from 'formik'

import { 
  InputComponent, 
  InputField, 
  InputFormikField, 
  StyledStar, 
  StyledError, 
  StyledCheckbox, 
  StyledRadio,
  StyledLabel,
  StyledTextLabel 
}  from './styles'

export default function Input({
  label,
  error,
  touched,
  required,
  blockVariant,
  isFormik,
  type,
  id,
  ...rest
}) {
  const isCheckbox = type === 'checkbox'
  const isRadio = type === 'radio'

  const labelEl = <>
    {label && <StyledTextLabel htmlFor={id}>
      {label}
      {required && <StyledStar tag={'span'}>*</StyledStar>}
    </StyledTextLabel>}
  </>

  const field = <>
    {labelEl}
    {!isFormik ? (
      <InputField {...rest} type={type} id={id} />
    ): (
      <InputFormikField {...rest} type={type} id={id} />
    )}
  </>

  const checkbox = <>
    <StyledLabel>
      <Field {...rest} type={type} />
      <StyledCheckbox className={'fake'} />
      <Typography tag={'span'}>{label}</Typography>
    </StyledLabel>
  </>

  const radio = <>
    <StyledLabel>
      <Field {...rest} type={type} />
      <StyledRadio className={'fake'} />
      <Typography tag={'span'}>{label}</Typography>
    </StyledLabel>
  </>

  return (
    <InputComponent variant={blockVariant}>
      {isRadio && radio}
      {isCheckbox && checkbox}
      {!isCheckbox && !isRadio && field}
      {error && touched && (
        <StyledError tag={'span'}>{error}</StyledError>
      )}
    </InputComponent>
  ) 
}

Input.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  blockVariant: PropTypes.string,
  isFormik: PropTypes.bool
}

Input.defaultProps = {
  variant: 'default',
  type: 'text',
  value: undefined,
  placeholder: '',
  label: undefined,
  blockVariant: 'column',
  isFormik: false
}
