import { ROUTERS_PATH } from 'constants/routers'

import Login from 'pages/Login'
import Home from 'pages/Home'
import Search from 'pages/Search'
import Posts from 'pages/Posts'
import SinglePost from 'pages/SinglePost'
import Account from 'pages/Account'
import Contact from 'pages/Contact'
import Faq from 'pages/Faq'
import NotFound from 'pages/NotFound'

export const routesObj = [{
  path: ROUTERS_PATH.HOME,
  component: Home,
  public: false
}, {
  path: ROUTERS_PATH.SEARCH,
  component: Search,
  public: false
}, {
  path: ROUTERS_PATH.NEWS,
  component: Posts,
  public: false
}, {
  path: `${ROUTERS_PATH.NEWS}/:topicId`,
  component: SinglePost,
  public: false
}, {
  path: ROUTERS_PATH.EVENTS,
  component: Posts,
  public: false
}, {
  path: `${ROUTERS_PATH.EVENTS}/:topicId`,
  component: SinglePost,
  public: false
}, {
  path: ROUTERS_PATH.OFFERS,
  component: Posts,
  public: false
}, {
  path: `${ROUTERS_PATH.OFFERS}/:topicId`,
  component: SinglePost,
  public: false
}, {
  path: ROUTERS_PATH.FOODS,
  component: Posts,
  public: false
}, {
  path: `${ROUTERS_PATH.FOODS}/:topicId`,
  component: SinglePost,
  public: false
}, {
  path: ROUTERS_PATH.LIFESTYLE,
  component: Posts,
  public: false
}, {
  path: `${ROUTERS_PATH.LIFESTYLE}/:topicId`,
  component: SinglePost,
  public: false
}, {
  path: ROUTERS_PATH.ACCOUNT,
  component: Account,
  public: false
}, {
  path: ROUTERS_PATH.CONTACT,
  component: Contact,
  public: false
}, {
  path: ROUTERS_PATH.FAQ,
  component: Faq,
  public: false
}, {
  path: ROUTERS_PATH.LOGIN,
  component: Login,
  public: true
}, {
  path: ROUTERS_PATH.NOT_FOUND,
  component: NotFound,
  public: false
}, {
  path: '',
  component: NotFound,
  public: false
}]