// import routes from 'routes/routes'
import TYPES from 'store/types'

const { LIFESTYLE } = TYPES

const initialState = {
  //currentRoute: routes.examples,
  loading: false,
  content: {},
  currentItem: {},
  filtered: [],
  activePage: 1
}

const handlers = {
  [LIFESTYLE.GET_ALL_PENDING]: (state) => ({
    ...state,
    loading: true
  }),

  [LIFESTYLE.GET_ALL_SUCCESS]: (state, payload) => ({
    ...state,
    loading: false,
    content: payload
  }),

  [LIFESTYLE.GET_ALL_FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),

  [LIFESTYLE.GET_BY_ID_PENDING]: (state) => ({
    ...state,
    loading: true
  }),

  [LIFESTYLE.GET_BY_ID_SUCCESS]: (state, payload) => ({
    ...state,
    loading: false,
    currentItem: payload
  }),

  [LIFESTYLE.GET_BY_ID_FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),

  [LIFESTYLE.SET_ACTIVE_PAGE]: (state,  payload) => ({
    ...state,
    activePage: payload
  }),

  [LIFESTYLE.GET_FILTERED]: (state, payload) => {
    const filtered = state.filtered
    const rez = filtered.indexOf(payload) > -1 
            ? filtered.filter(id => id !== payload) 
            : [...filtered, payload]

    return {
      ...state,
      filtered: rez
    }
  },

  default: (state) => state,
}

const reducerLifestyle = (state = initialState, { type, payload }) => {
  const handler = handlers[type] || handlers.default
  return handler(state, payload)
}

export default reducerLifestyle
