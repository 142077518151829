import React from 'react'

import PostContent from 'components/organisms/PostContent'

import styled from 'styled-components'

const StyledPage = styled.div`
  text-align: center;
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`

export default function NotFound() {
  const data = {
    item_title: '404',
    item_subtitle: 'This page is not found'
  }

  return (
    <>
      <StyledPage>
        {(data.item_title || data.item_subtitle || data.item_content) && (
        <PostContent 
          title={data.item_title} 
          subTitle={data.item_subtitle} 
          content={data.item_content} 
        />)}
      </StyledPage>
    </>
  );
}
