import React from 'react'
import PropTypes from 'prop-types'

import { Helper } from 'helpers/simple'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

export const StyledLink = styled(Link)`
  ${props => props.theme.link};
`
export const DefaultLink = styled.a`
  ${props => props.theme.link};
`

export default function LinkItem({ 
  children, 
  to, 
  target, 
  notLink, 
  ...rest 
}) {
  return (
    <>
      {(!Helper.isRefLink(to) && !notLink) ? (
        <StyledLink to={to} {...rest}>{children}</StyledLink>
      ) : (
        <DefaultLink 
          href={to}
          target={target} 
          rel={target ? 'noreferrer noopener' : ''}
          {...rest}
        >
          {children}
        </DefaultLink>
      )}
    </>
  )
}

LinkItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  to: PropTypes.string.isRequired,
  notLink: PropTypes.bool,
  target: PropTypes.string,
}

LinkItem.defaultProps = {
  notLink: false,
  target: ''
}
