import styled from 'styled-components'
import Typography from 'components/atoms/Typography'
import LinkItem from 'components/atoms/Link'

const StyledLatest = styled.div`
  padding-top: 21px;
`

const StyledTitle = styled(Typography)`
  font-size: ${props => props.theme.fontSize.large};
  margin-bottom: 24px;
`

const StyledLinkMore = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 14px;
  margin-bottom: -10px;

  span {
    font-family: ${props => props.theme.fontFamily.add};
    color: ${props => props.theme.colors.gray90};
  }
`

const StyledLink = styled(LinkItem)`
  display: flex;
  flex-direction: column;

  i {
    position: relative;
    left: 34px;
    top: -8px;
  }
`

const StyledPagination = styled.div`
  padding-top: 20px;

  .pagination {
    ${props => props.theme.dFlexAlignCenter};
    justify-content: center;
  }

  li {
    padding: 0 4px;

    &.active {
      a {
        color: ${props => props.theme.colors.pink};
      }
    }

    &.disabled {
      opacity: 0.3;
    }
  }

  a {
    color: ${props => props.theme.colors.gray90};
  }
`

export {
  StyledLatest,
  StyledTitle,
  StyledLinkMore,
  StyledLink,
  StyledPagination
};