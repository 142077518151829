import React from 'react'
import PropTypes from 'prop-types'

import { StyledMap, StyledIframe } from './styles'

export default function MapBlock({ children, ...rest }) {
  return (
    <StyledMap {...rest}>
      <StyledIframe dangerouslySetInnerHTML={{ __html: children }} />
    </StyledMap>
  )
}

MapBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, 
    PropTypes.string
  ]).isRequired,
}