import { put, takeLatest, call } from 'redux-saga/effects'

import TYPES from 'store/types'
import actionsApp from 'store/app/actions'
import { http, endpoints } from 'helpers/http'
import { ROUTERS_PATH } from 'constants/routers'

function* fetchLayout() {
  try {
    const response = yield http.get(`${endpoints.base}main`)

    if (response.success) {
      yield put(actionsApp.layoutSuccess(response))
    } else {
      yield put(actionsApp.layoutFailure())
    }
  } catch(error) {
    yield put(actionsApp.layoutFailure())
  }
}

function* actionWatcherLayout() {
  yield takeLatest(TYPES.LAYOUT.GET_ALL_PENDING, fetchLayout)
}

function* fetchSearch({ param, history }) {
  try {
    const response = yield http.get(`${endpoints.base}search?search=${param}`)

    if (response.success) {
      yield put(actionsApp.searchSuccess(response))
      yield call(history.push, ROUTERS_PATH.SEARCH)
    } else {
      yield put(actionsApp.searchFailure())
    }
  } catch(error) {
    yield put(actionsApp.searchFailure())
  }
}

function* actionWatcherSearch() {
  yield takeLatest(TYPES.SEARCH.GET_ALL_PENDING, fetchSearch)
}

export { actionWatcherLayout, actionWatcherSearch }
