import styled from 'styled-components'

const StyledMenu = styled.ul`
  &.line {
    ${props => props.theme.dFlexBetween};
    align-items: flex-start;
  }
`

const StyledItem = styled.li`
  .line & {
    padding: 0 4px;
  }
`

export { StyledMenu, StyledItem };