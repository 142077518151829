import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/atoms/Icon'
import Typography from 'components/atoms/Typography'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share"

import { StyledShared, StyledOpener, StyledDrop } from './styles'

export default function SharedBlock({ children, ...rest }) {
  const [ isOpen, setIsOpen ] = useState(false)
  const onClick = () => setIsOpen(!isOpen)
  const url = 'http://sugarhive.ph.stgnew.com/';

  return (
    <StyledShared {...rest}>
      <StyledOpener onClick={onClick}>
        <Icon name={'share'} color={'black'} fontSize={'22'} />
        <Typography tag={'span'}>{children}</Typography>
      </StyledOpener>
      {isOpen && <StyledDrop>
        <EmailShareButton url={url}>
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
        <FacebookShareButton url={url}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <LinkedinShareButton url={url}>
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
        <TwitterShareButton url={url}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </StyledDrop>}
    </StyledShared>
  )
}

SharedBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, 
    PropTypes.string
  ]).isRequired,
}

