import React from 'react'
import PropTypes from 'prop-types'

import Typography from 'components/atoms/Typography'
import Image from 'components/atoms/Image'

import { StyledTile } from './styles'

export default function TileItem({ content, ...rest }) {
  const {
    image,
    text,
    item_type
  } = content 

  return (
    <StyledTile 
      className={!image.posCenter && 'full'}
      image={!image.posCenter ? image.src : ''}
      to={`/${item_type}`}
      {...rest}
    >
      {image.posCenter && <Image params={image} />}
      <Typography
        tag={'h2'} 
        variant={'large'}
      >
        {text}
      </Typography>
    </StyledTile>
  )
}

TileItem.propTypes = {
  content: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    text: PropTypes.string,
    item_type: PropTypes.string
  })
}

TileItem.propDefault = {
  content: {
    image: {
      src: '',
      alt: ''
    },
    text: '',
    item_type: ''
  }
}