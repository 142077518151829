import TYPES from 'store/types'

const actionsFaq = {
  faqPending: () => ({
    type: TYPES.FAQ.GET_PAGE_PENDING,
  }),

  faqSuccess: (payload) => ({
    type: TYPES.FAQ.GET_PAGE_SUCCESS,
    payload,
  }),

  faqFailure: () => ({
    type: TYPES.FAQ.GET_PAGE_FAILURE,
  }),
}

export default actionsFaq
