import styled from 'styled-components'

const StyledAddress = styled.div`
  padding-top: 33px;
  padding-bottom: 10px;

  h3 {
    margin-bottom: 24px;
  }
`

const StyledAdd = styled.div`
  margin-bottom: 22px;

  span {
    margin-right: 3px;
  }

  a {
    color: inherit;
  }
`

const StyledAddressText = styled.div`
  padding-bottom: 8px;
`

export { StyledAddress, StyledAdd, StyledAddressText }