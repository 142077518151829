import { combineReducers } from 'redux'

import reducerApp from 'store/app/reducer'
import reducerNews from 'store/news/reducer'
import reducerOffers from 'store/offers/reducer'
import reducerEvents from 'store/events/reducer'
import reducerFoods from 'store/foods/reducer'
import reducerLifestyle from 'store/lifestyle/reducer'
import reducerAccount from 'store/account/reducer'
import reducerContact from 'store/contact/reducer'
import reducerFaq from 'store/faq/reducer'
import reducerLogin from 'store/login/reducer'
import reducerHome from 'store/home/reducer'

const rootReducer = combineReducers({
  app: reducerApp,
  news: reducerNews,
  offers: reducerOffers,
  events: reducerEvents,
  foods: reducerFoods,
  lifestyle: reducerLifestyle,
  account: reducerAccount,
  contact: reducerContact,
  faq: reducerFaq,
  login: reducerLogin,
  home: reducerHome
})

export default rootReducer
