import styled from 'styled-components'
import Typography from 'components/atoms/Typography'
import { Field } from 'formik'

const InputComponent = styled.div`
  ${props => props.theme.inputBlock[props.variant]};
`

const StyledTextLabel = styled.label`
  margin-bottom: 11px;
  width: 100%;
`

const InputField = styled.input`
  ${props => props.theme.input[props.variant]};
`

const InputFormikField = styled(Field)`
  ${props => props.theme.input[props.variant]};
`

const StyledStar = styled(Typography)`
  color: ${props => props.theme.colors.pink};
`

const StyledError = styled(Typography)`
  color: ${props => props.theme.colors.pink};
  font-size: ${props => props.theme.fontSize.mini};
`

const StyledLabel = styled.label`
  ${props => props.theme.dFlexAlignCenter};

  input {
    position: fixed;
    left: -9999px;
    top: -9999px;
    opacity: 0;
  }
`

const StyledCheckbox = styled.span`
  margin: 0 4px 0 0;
  width: 16px;
  height: 16px;
  display: block;
  border: 1px solid ${props => props.theme.colors.gray70};

  input:checked + & {
    background: ${props => props.theme.colors.gray70};
  }
`

const StyledRadio = styled(Field)`
  border: 1px solid ${props => props.theme.colors.gray70};
  border-radius: 50%;
  margin: 0 4px 0 0;
  width: 16px;
  height: 16px;
  display: block;

  input:checked + & {
    background: ${props => props.theme.colors.gray70};
  }
`

export {
  InputComponent,
  InputField,
  InputFormikField,
  StyledStar,
  StyledError,
  StyledCheckbox,
  StyledRadio,
  StyledLabel,
  StyledTextLabel
};