import TYPES from 'store/types'

const actionsEvents = {
  eventsPending: () => ({
    type: TYPES.EVENTS.GET_ALL_PENDING,
  }),

  eventsSuccess: (payload) => ({
    type: TYPES.EVENTS.GET_ALL_SUCCESS,
    payload,
  }),

  eventsFailure: () => ({
    type: TYPES.EVENTS.GET_ALL_FAILURE,
  }),

  eventsSinglePending: (id) => ({
    type: TYPES.EVENTS.GET_BY_ID_PENDING,
    id
  }),

  eventsSingleSuccess: (payload) => ({
    type: TYPES.EVENTS.GET_BY_ID_SUCCESS,
    payload,
  }),

  eventsSingleFailure: () => ({
    type: TYPES.EVENTS.GET_BY_ID_FAILURE,
  }),

  eventsFiltered: (payload) => ({
    type: TYPES.EVENTS.GET_FILTERED,
    payload
  }),

  eventsSetActivePage: (payload) => ({
    type: TYPES.EVENTS.SET_ACTIVE_PAGE,
    payload
  })
}

export default actionsEvents
