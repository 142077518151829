import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import actionsHome from 'store/home/actions'
import { getHomePage } from 'store/home/selectors'

export const usePageActions = () => {
  const dispatch = useDispatch()
  const data = useSelector(getHomePage)

  useEffect(() => {
    ;(() => dispatch(actionsHome.homePending()))()
  }, [dispatch])

  return { data }
}

  
