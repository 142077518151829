import React from 'react'

import PromoBlock from 'components/molecules/PromoBlock'
import HeroSlider from 'components/organisms/HeroSlider'
import TilesBlock from 'components/organisms/TilesBlock'
import LatestBlock from 'components/templates/LatestBlock'

import { usePageActions } from './hooks'

export default function Home() {
  const { data } = usePageActions()

  return (
    <>
      {data.item_gallery && (
        <HeroSlider arr={data.item_gallery} />
      )}
      {data.items_section && (
        <LatestBlock 
          content={data.items_section} 
          titleVariant={'large'} 
        />
      )}
      {data.promo && (
        <PromoBlock mb={'0'} content={data.promo} />
      )}
      {data.block_tiles && (
        <TilesBlock content={data.block_tiles} />
      )}
      {data.other_items_section && (
        <LatestBlock content={data.other_items_section} inline />
      )}
    </>
  );
}
