import { put, takeLatest } from 'redux-saga/effects'

import TYPES from 'store/types'
import actionsNews from 'store/news/actions'
import { http, endpoints } from 'helpers/http'

function* fetchNewsAll() {
  try {
    const response = yield http.get(`${endpoints.base}news`)

    if (response.success) {
      yield put(actionsNews.newsSuccess(response))
    } else {
      yield put(actionsNews.newsFailure())
    }
  } catch(error) {
    yield put(actionsNews.newsFailure())
  }
}

function* actionWatcherNewsAll() {
  yield takeLatest(TYPES.NEWS.GET_ALL_PENDING, fetchNewsAll)
}

function* fetchNewsById({ id }) {
  try {
    const response = yield http.get(`${endpoints.base}news/${id}`)

    yield put(actionsNews.newsSingleSuccess(response))
  } catch(error) {
    yield put(actionsNews.newsSingleFailure())
  }
}

function* actionWatcherNewsById() {
  yield takeLatest(TYPES.NEWS.GET_BY_ID_PENDING, fetchNewsById)
}

export { actionWatcherNewsById, actionWatcherNewsAll }
