import React from "react";
import PropTypes from 'prop-types'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Image from 'components/atoms/Image'

import { StyledSlider } from './styles'

SwiperCore.use([Pagination])

export default function Slider({ arr }) {
  return (
    <StyledSlider>
      <Swiper
        pagination={{ clickable: true }}
      >
        {arr.map((item) =>(
          <SwiperSlide key={item.key}>
            <Image params={item} full />
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledSlider>
  )
}

Slider.propTypes = {
  arr: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    src: PropTypes.string
  })).isRequired,
}

