import TYPES from 'store/types'

const actionsOffers = {
  offersPending: () => ({
    type: TYPES.OFFERS.GET_ALL_PENDING,
  }),

  offersSuccess: (payload) => ({
    type: TYPES.OFFERS.GET_ALL_SUCCESS,
    payload,
  }),

  offersFailure: () => ({
    type: TYPES.OFFERS.GET_ALL_FAILURE,
  }),

  offersSinglePending: (id) => ({
    type: TYPES.OFFERS.GET_BY_ID_PENDING,
    id
  }),

  offersSingleSuccess: (payload) => ({
    type: TYPES.OFFERS.GET_BY_ID_SUCCESS,
    payload,
  }),

  offersSingleFailure: () => ({
    type: TYPES.OFFERS.GET_BY_ID_FAILURE,
  }),

  offersFiltered: (payload) => ({
    type: TYPES.OFFERS.GET_FILTERED,
    payload
  }),

  offersSetActivePage: (payload) => ({
    type: TYPES.OFFERS.SET_ACTIVE_PAGE,
    payload
  })
}

export default actionsOffers
