import { put, takeLatest } from 'redux-saga/effects'

import TYPES from 'store/types'
import actionsFaq from 'store/faq/actions'
import { http, endpoints } from 'helpers/http'

function* fetchFaqPage() {
  try {
    const response = yield http.get(`${endpoints.base}faq`)

    if (response.success) {
      yield put(actionsFaq.faqSuccess(response))
    } else {
      yield put(actionsFaq.faqFailure())
    }
  } catch(error) {
    yield put(actionsFaq.faqFailure())
  }
}

function* actionWatcherFaq() {
  yield takeLatest(TYPES.FAQ.GET_PAGE_PENDING, fetchFaqPage)
}

export { actionWatcherFaq }
