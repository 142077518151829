import { put, takeLatest } from 'redux-saga/effects'

import TYPES from 'store/types'
import actionsLifestyle from 'store/lifestyle/actions'
import { http, endpoints } from 'helpers/http'

function* fetchLifestyleAll() {
  try {
    const response = yield http.get(`${endpoints.base}lifestyle`)

    if (response.success) {
      yield put(actionsLifestyle.lifestyleSuccess(response))
    } else {
      yield put(actionsLifestyle.lifestyleFailure())
    }
  } catch(error) {
    yield put(actionsLifestyle.lifestyleFailure())
  }
}

function* actionWatcherLifestyleAll() {
  yield takeLatest(TYPES.LIFESTYLE.GET_ALL_PENDING, fetchLifestyleAll)
}

function* fetchLifestyleById({ id }) {
  try {
    const response = yield http.get(`${endpoints.base}lifestyle/${id}`)

    yield put(actionsLifestyle.lifestyleSingleSuccess(response))
  } catch(error) {
    yield put(actionsLifestyle.lifestyleSingleFailure())
  }
}

function* actionWatcherLifestyleById() {
  yield takeLatest(TYPES.LIFESTYLE.GET_BY_ID_PENDING, fetchLifestyleById)
}

export { actionWatcherLifestyleById, actionWatcherLifestyleAll }
