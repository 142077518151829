import React from 'react'
import PropTypes from 'prop-types'

import Typography from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'

import { StyledContent, StyledCalendar, StyledText } from './styles'

export default function PostContent({
  title, 
  subTitle, 
  calendar, 
  content, 
  ...rest 
}) {
  return (
    <StyledContent {...rest}>
      {title && <Typography tag={'h1'}>{title}</Typography>}
      {subTitle && <Typography tag={'strong'} variant={'h2'}>{subTitle}</Typography>}
      {calendar && (
        <StyledCalendar>
          <Icon name={'small-calendar'} />
          <Typography tag={'h2'}>{calendar}</Typography>
        </StyledCalendar>
      )}
      {content && <StyledText dangerouslySetInnerHTML={{ __html: content }} />}
    </StyledContent>
  )
}

PostContent.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  calendar: PropTypes.string,
  content: PropTypes.string,
}

PostContent.defaultProps = {
  title: '',
  subTitle: '',
  calendar: '',
  content: '',
}
