import React from 'react'
import PropTypes from 'prop-types'
import Component from './styles'

export default function Badge({
  children,
  disabled,
  active,
  ...rest
}) {
  return <Component className={active && 'active'} {...rest}>{children}</Component>
}

Badge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, 
    PropTypes.string
  ]).isRequired,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
}

Badge.defaultProps = {
  disabled: false,
  variant: 'default',
  active: false
}