import React from 'react'
import PropTypes from 'prop-types'

import MenuItem from 'components/molecules/MenuItem'

import { StyledMenu, StyledItem } from './styles'

export default function MenuList({
  menu,
  onMenuClick,
  isMenuOpen,
  line,
  ...rest
}) {
  return (
    <StyledMenu className={line ? 'line' : ''} {...rest}>
      {menu.map(item => (
        <StyledItem key={item.key}>
          <MenuItem 
            item={item} 
            onMenuClick={onMenuClick}
            isMenuOpen={isMenuOpen}
            inline={!line}
          />
        </StyledItem>
      ))}
    </StyledMenu>
  )
}

MenuList.propTypes = {
  menu: PropTypes.array.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  line: PropTypes.bool,
}

MenuList.defaultProps = {
  line: false,
}