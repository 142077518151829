import styled from 'styled-components'

const StyledShared = styled.div`
  ${props => props.theme.fullSize};
  background: ${props => props.theme.colors.yellow};
  color: ${props => props.theme.colors.gray90};
  font-size: ${props => props.theme.fontSize.big};
  text-decoration: none;
  margin-bottom: 25px;
  margin-top: 7px;

  i {
    margin-right: 14px;
  }
`

const StyledOpener = styled.div`
  ${props => props.theme.dFlexAlignCenter};
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
`

const StyledDrop = styled.div`
  ${props => props.theme.dFlexAlignCenter};
  padding-bottom: 20px;

  button {
    height: 32px;
    margin-right: 10px;

    &:focus {
      outline: none;
    }
  }
`

export { StyledShared, StyledOpener, StyledDrop }