import React from 'react'
import PropTypes from 'prop-types'

import Typography from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'

import { StyledLink } from './styles'

export default function MenuLink({
  icon,
  iconFont,
  text,
  ...rest
}) {
  return (
    <StyledLink {...rest}>
      <Icon name={icon} fontSize={iconFont}/>
      <Typography tag={'span'}>{text}</Typography>
    </StyledLink>
  )
}

MenuLink.propTypes = {
  icon: PropTypes.string,
  iconFont: PropTypes.number,
  text: PropTypes.string
}

MenuLink.defaultProps = {
  icon: 'logo',
  iconFont: 30,
  text: ''
}