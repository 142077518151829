import { put, takeLatest, call } from 'redux-saga/effects'

import TYPES from 'store/types'
import actionsLogin from 'store/login/actions'

import { http, endpoints } from 'helpers/http'
import { Helper } from 'helpers/simple'
import { NAME_STORAGE } from 'constants/nameStorage'
import { ROUTERS_PATH } from 'constants/routers'

function* fetchLoginPage() {
  try {
    const response = yield http.get(`${endpoints.base}login`)

    if (response.success) {
      yield put(actionsLogin.loginSuccess(response))
    } else {
      yield put(actionsLogin.loginFailure())
    }
  } catch(error) {
    yield put(actionsLogin.loginFailure())
  }
}

function* actionWatcherLogin() {
  yield takeLatest(TYPES.LOGIN.GET_PAGE_PENDING, fetchLoginPage)
}

function* postLoginPage({ data, history }) {
  try {
    const response = yield http.post(`${endpoints.token}token`, data.values)
    
    if (response.success) {
      Helper.setItem(NAME_STORAGE.TOKEN, response.data.token, data.checked)
      yield put(actionsLogin.loginSentSuccess(response))
      yield call(history.push, ROUTERS_PATH.HOME)
    } else {
      yield put(actionsLogin.loginSentFailure(response))
    }
  } catch(error) {
    yield put(actionsLogin.loginSentFailure())
  }
}

function* actionSenderLogin() {
  yield takeLatest(TYPES.LOGIN.POST_PAGE_PENDING, postLoginPage)
}

export { actionWatcherLogin, actionSenderLogin }
