import React from 'react'

import PostContent from 'components/organisms/PostContent'
import DefaultForm from 'components/templates/DefaultForm'

import { usePageActions } from './hooks'

import styled from 'styled-components'

const StyledPageContent = styled.div`
  ${props => props.theme.fullSize};
  background-color: ${props => props.theme.colors.lYellow};
  padding-top: 28px;
  padding-bottom: 2px;

  h1 {
    margin-bottom: 9px;
  }

  h1 + div {
    padding-top: 0;
  }
`

const StyledForm = styled.div`
  padding-top: 21px;
  padding-bottom: 10px;
`

export default function Login() {
  const {
    data,
    dataError,
    dataSuccess,
    handleClick,
    onRemember
  } = usePageActions()

  return (
    <>
      {(data.item_title || data.item_content) && (
        <StyledPageContent>
          <PostContent 
            title={data.item_title} 
            content={data.item_content} 
          />
        </StyledPageContent>
      )}
      {data.loginForm && (
        <StyledForm>
          <DefaultForm 
            content={data.loginForm} 
            handleClick={handleClick} 
            dataError={dataError}
            dataSuccess={dataSuccess}
            schema={'LOGIN'}
            onRemember={onRemember}
          />
        </StyledForm>
      )}
    </>
  );
}
