import React from 'react'
import PropTypes from 'prop-types'
import Component from './styles'

export default function Image({ params, full, ...rest }) {
  const { src, alt } = params

  return (
    <Component src={src} alt={alt} full={full} {...rest} />
  )
}

Image.propTypes = {
  params: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  full: PropTypes.bool
}

Image.defaultProps = {
  params: {
    src: '',
    alt: '',
  },
  full: false
}
