import get from 'lodash/get'
import { createSelector } from 'reselect'

export const getLayout = createSelector(
  (state) => get(state, ['app', 'layout']) || {},
  (layout) => layout,
)

export const getSearch = createSelector(
  (state) => get(state, ['app', 'search']) || {},
  (search) => search,
)

export const getSearchActivePage = createSelector(
  (state) => get(state, ['app', 'activePageSearch']) || [],
  (activePageSearch) => activePageSearch,
)