import React from 'react'
import PropTypes from 'prop-types'

import TileItem from 'components/molecules/TileItem'

import { StyledContent, StyledLeftBlock, StyledRightBlock } from './styles'

export default function TilesBlock({
  content, 
  ...rest 
}) {
  const { leftBlock, rightBlock } = content

  return (
    <StyledContent {...rest}>
      {leftBlock && <StyledLeftBlock>
        {leftBlock.map((item) => (
          <TileItem key={item.key} content={item} />
        ))}
      </StyledLeftBlock>}
      {rightBlock && <StyledRightBlock>
        {rightBlock.map((item) => (
          <TileItem key={item.key} content={item} />
        ))}
      </StyledRightBlock>}
    </StyledContent>
  )
}

TilesBlock.propTypes = {
  content: PropTypes.shape({
    leftBlock: PropTypes.arrayOf(PropTypes.object),
    rightBlock: PropTypes.arrayOf(PropTypes.object)
  }),
}

TilesBlock.defaultProps = {
  content: {
    leftBlock: [],
    rightBlock: []
  },
}
