import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/atoms/Icon'
import Typography from 'components/atoms/Typography'
import LatestList from 'components/organisms/LatestList'

import Pagination from 'react-js-pagination'
import {
  StyledLatest,
  StyledTitle,
  StyledLinkMore,
  StyledLink,
  StyledPagination
} from './styles'

export default function LatestBlock({ 
  children,
  content, 
  inline,
  titleVariant,
  onPagingClick,
  activePage,
  ...rest 
}) {
  const { title, list, link } = content
  const countPerPage = 10

  return (
    <StyledLatest {...rest}>
      {console.log(content)}
      <StyledTitle 
        tag={'h2'}
        variant={titleVariant}
      > {title}
      </StyledTitle>
      <LatestList 
        inline={inline}
        arr={list} 
        addBlock={children} 
        countPerPage={countPerPage}
        activePage={activePage}
      />
      {list.length > countPerPage && !inline &&  (
        <StyledPagination>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={countPerPage}
            totalItemsCount={list.length}
            pageRangeDisplayed={5}
            onChange={onPagingClick}
          />
        </StyledPagination>
      )}
      {link && (
        <StyledLinkMore>
          <StyledLink to={`/${link.item_type}`}>
            <Typography tag={'span'}>{link.text}</Typography>
            <Icon name={'decor-arrow'} fontSize={'55'} color={'yellow'} />
          </StyledLink>
        </StyledLinkMore>
      )}
    </StyledLatest>
  )
}

LatestBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, 
    PropTypes.string
  ]),
  content: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
    link: PropTypes.object
  }),
  inline: PropTypes.bool,
  titleVariant: PropTypes.string,
  activePage: PropTypes.number
}

LatestBlock.defaultProps = {
  children: null,
  content: {},
  inline: false,
  titleVariant: '',
  activePage: 1
}