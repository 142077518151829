import styled from 'styled-components'

const StyledItem = styled.div`
  position: relative;
  display: flex;
`

const StyledIcon = styled.div`
  ${props => props.theme.listIcon};
  ${props => props.theme.listIcon[props.variant]};

  &:before {
    content: '';
  }

  i {
    position: relative;
    font-size: 11px;
    top: -2px;

    &.icon-like {
      font-size: 14px;
    }

    &.icon-ticket  {
      font-size: 10px;
    }

    &.icon-bookmark {
      font-size: 12px;
    }
  }
`

const StyledItemBody = styled.div`
  color: ${props => props.theme.colors.gray90};

  h3 {
    margin-bottom: 4px;
  }
`

export { StyledItem, StyledIcon, StyledItemBody }