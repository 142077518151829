const colors = {
  white: '#ffffff',
  black: '#000000',
  pink: '#DB2955',
  yellow: '#FFC32E',
  lYellow: '#FFF3D5',
  gray10: '#A8A8A8',
  gray20: '#B3B3B3',
  gray70: '#707070',
  gray90: '#0D0D0D',
  brown: '#2E2E2E',
}

const fontWeight = {
  100: '100',
  200: '200',
  300: '300',
  400: '400',
  500: '500',
  600: '600',
  700: '700',
  800: '800',
  900: '900',
}

const fontSizeTitles = {
  h1: '32px',
  h2: '18px',
  h3: '16px',
}

const fontSize = {
  ...fontSizeTitles,
  large: '24px',
  big: '20px',
  base: '16px',
  small: '14px',
  mini: '12px',
  micro: '8px'
}

const fontFamily = {
  base: `'Poppins', 'Helvetica Neue', Arial`,
  add: `'Poppins', 'Helvetica Neue', Arial`
}

const typographyPrimary = {
  textDecoration: 'none',
  '&:focus, &:active, &:hover': {
    outline: 'none',
    textDecoration: 'none',
    border: 0,
  },
}

const typographyTitles = {
  ...typographyPrimary,
  fontFamily: fontFamily.add,
  lineHeight: 1.2,
  display: 'block'
}

const transitionBtn = {
  transition: 'background 0.3s, color 0.3s'
}

const transitionOpacity = {
  transition: 'opacity 0.3s linear'
}

const buttonsDefault = {
  ...transitionBtn,
  cursor: 'pointer',
  padding: '19px 40px',
  fontSize: fontSize.big,
  fontFamily: fontFamily.add,
  lineHeight: 1.35,
  borderRadius: 60,
  minWidth: 162
}

const badgeDefault = {
  ...transitionBtn,
  display: 'inline-block',
  verticalAlign: 'top',
  fontSize: fontSize.small,
  borderRadius: 25,
  padding: '7px 15px',
  cursor: 'pointer',
}

const fixedBlock = {
  position: 'fixed',
  left: 0,
  right: 0,
  topPos: {
    top: 0
  },
  botPos: {
    bottom: 0
  },
  zIndex: 9999,
}

const sliderDotButton = {
  border: `2px solid ${colors.white}`,
  borderRadius: '50%',
  margin: 0,
  padding: 0,
  width: 13,
  height: 13,

  '&:before': {
    display: 'none'
  }
}

const sliderkDots = {
  background: 'none',
  opacity: 1,
  margin: '0 3px',
  borderRadius: '50%',
  overflow: 'hidden',
  width: 13,
  height: 13,

  '&.swiper-pagination-bullet-active': {
    background: colors.white
  },

  '&:only-child': {
    display: 'none'
  },

  '&:focus': {
    outline: 'none'
  }
}

const dFlexAlignCenter = {
  display: 'flex',
  alignItems: 'center'
}

const dFlexBetween = {
  ...dFlexAlignCenter,
  justifyContent: 'space-between',
}

const container = {
  paddingLeft: 18,
  paddingRight: 18
}

const fullContainer = {
  marginLeft: -18,
  marginRight: -18,
}

const fullSize = {
  ...container,
  ...fullContainer,
  overflow: 'hidden'
}

const link = {
  textDecoration: 'none',
  '&:hover': {
    color: colors.pink
  }
}

const fullAbsolute = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
}

const themeMain = {
  colors,
  fontSize,
  fontWeight,
  fontFamily,
  fixedBlock,
  transitionOpacity,
  dFlexAlignCenter,
  dFlexBetween,
  container,
  fullContainer,
  fullSize,
  fullAbsolute,
  link,
  sliderkDots,
  sliderDotButton,

  typography: {
    h1: {
      ...typographyTitles,
      fontSize: fontSize.h1,
      marginBottom: 15
    },
    h2: {
      ...typographyTitles,
      fontSize: fontSize.h2,
      marginBottom: 15
    },
    h3: {
      ...typographyTitles,
      fontSize: fontSize.h3,
      marginBottom: 15
    },
    base: {
      fontSize: fontSize.base
    },
    date: {
      fontSize: fontSize.small,
      color: colors.gray10
    },
  },
  buttons: {
    primary: {
      ...buttonsDefault,
      background: colors.pink,
      border: `1px solid ${colors.pink}`,
      color: colors.white,
      '&:hover': {
        background: colors.white,
        color: colors.pink
      }
    },
    gray: {
      ...buttonsDefault,
      border: `1px solid ${colors.gray10}`,
      background: colors.gray10,
      color: colors.gray90,
      '&:hover': {
        background: colors.white
      }
    }
  },
  badge: {
    default: {
      ...badgeDefault,
      border: `1px solid ${colors.gray90}`,
    },
    active: {
      ...badgeDefault,
      background: colors.yellow,
      borderColor: colors.yellow
    }
  },
  inputBlock: {
    inline: {
      ...dFlexAlignCenter
    },
    column: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  input: {
    default: {
      padding: '4px 15px',
      border: `1px solid ${colors.gray70}`,
      color: colors.gray70,
      height: 40,
      width: '100%'
    },
    black: {
      fontSize: fontSize.small,
      background: colors.gray90,
      color: colors.white,
      border: 'none',
      minWidth: 267,
      height: 36,
      padding: '4px 15px 4px 36px',
      width: '100%',
      '&::placeholder': {
        color: colors.white
      }
    }
  },
  listIcon: {
    icon: {
      width: 30,
      marginRight: 7,
      '&::before': {
        display: 'none',
      },
    },
    dot: {
      '&::before': {
        background: colors.pink,
        borderRadius: '50%',
        display: 'block',
        margin: '10px 19px 0 2px',
        width: 4,
        height: 4,
      }
    },
    check: {
      '&::before': {
        display: 'block',
        borderLeft: `2px solid ${colors.pink}`,
        borderBottom: `2px solid ${colors.pink}`,
        marginTop: 5,
        marginRight: 10,
        width: 12,
        height: 8,
        transform: 'rotate(-45deg)'
      }
    }
  }
}

export default themeMain