import React from 'react'
import PropTypes from 'prop-types'

import Image from 'components/atoms/Image';
import List from 'components/organisms/List';

import {
  StyledBlock,
  StyledTitle,
  StyledHeading,
  StyledText
} from './styles'

export default function DetailSection({ 
  content,
  iconVariant, 
  textDiff, 
  ...rest 
}) {
  const {
    icon,
    summary,
    list,
    title
  } = content
  
  return (
    <StyledBlock {...rest}>
      <StyledHeading>
        {icon && <Image params={icon} />}
        {title && <StyledTitle tag={'h2'}>{title}</StyledTitle>}
      </StyledHeading>
      {summary && <StyledText>{summary}</StyledText>}
      {list && list.length && (
        <List arr={list} variant={iconVariant} textDiff={textDiff} />
      )}
    </StyledBlock>
  )
}

DetailSection.propTypes = {
  iconVariant: PropTypes.string,
  textDiff: PropTypes.string,
  content: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
    summary: PropTypes.string,
    icon: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    })
  }),
}

DetailSection.defaultProps = {
  iconVariant: '',
  textDiff: '',
  content: {}
}