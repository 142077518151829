import React from 'react'
import { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'

import themeMain from './themeMain'

export default function ProviderTheme({ children }) {
  return <ThemeProvider theme={themeMain}>{children}</ThemeProvider>
}

ProviderTheme.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
}
