import * as Yup from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const VALIDATION_SCHEMA = {
  LOGIN: Yup.object().shape({
    username: Yup.string().required('Username or email address is a required field'),
    password: Yup.string().required('Password is a required field')
  }),
  CONTACT: Yup.object().shape({
    name: Yup.string().required('Name is a required field'),
    tel: Yup.string().required('Phone number is a required field').matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().email('Invalid email').required('Email is a required field'),
    businnes: Yup.string().required('Businnes is a required field')
  }),
}
