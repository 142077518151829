import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/atoms/Icon'
import ItemLink from 'components/atoms/Link'
import Image from 'components/atoms/Image'
import SearchBar from 'components/molecules/SearchBar'

import { ROUTERS_PATH } from 'constants/routers'

import { 
  StyledHeader, 
  StyledLogo, 
  StyledSearch, 
  StyledLogin 
} from './styles'

export default function Header({
  content,
  logo,
  onSearch,
  ...rest
}) {
  const { search, login_icon } = content

  return (
    <StyledHeader {...rest}>
      {logo && <StyledLogo to={ROUTERS_PATH.HOME}>
        <Image params={logo} />
      </StyledLogo>}
      {search && <StyledSearch>
        <SearchBar 
          placeholder={search} 
          onSearch={onSearch} 
        />
      </StyledSearch>}
      {login_icon && <ItemLink to={ROUTERS_PATH.ACCOUNT}>
        <StyledLogin title={'logout'}>
          <Icon 
            name={login_icon} 
            color={'yellow'} 
            fontSize={'22'} 
          />
        </StyledLogin>
      </ItemLink>}
    </StyledHeader>
  )
}

Header.propTypes = {
  content: PropTypes.shape({
    search: PropTypes.string,
    login_icon: PropTypes.string,
  }),
  logo: PropTypes.shape({
    img: PropTypes.string,
    alt: PropTypes.string,
  }),
  onSearch: PropTypes.func
}

Header.defaultProps = {
  content: {},
  logo: {}
}