import TYPES from 'store/types'

const actionsLogin = {
  loginPending: () => ({
    type: TYPES.LOGIN.GET_PAGE_PENDING,
  }),

  loginSuccess: (payload) => ({
    type: TYPES.LOGIN.GET_PAGE_SUCCESS,
    payload,
  }),

  loginFailure: () => ({
    type: TYPES.LOGIN.GET_PAGE_FAILURE,
  }),

  loginSentPending: (data, history) => ({
    type: TYPES.LOGIN.POST_PAGE_PENDING,
    data,
    history
  }),

  loginSentSuccess: (payload) => ({
    type: TYPES.LOGIN.POST_PAGE_SUCCESS,
    payload,
  }),

  loginSentFailure: (payload) => ({
    type: TYPES.LOGIN.POST_PAGE_FAILURE,
    payload
  }),
}

export default actionsLogin
