import React from 'react'

import AddressBlock from 'components/molecules/AddressBlock'
import PromoBlock from 'components/molecules/PromoBlock'
import MapBlock from 'components/molecules/MapBlock'
import PostContent from 'components/organisms/PostContent'
import DefaultForm from 'components/templates/DefaultForm'
//import { Helper } from 'helpers/simple'

import { usePageActions } from './hooks'

import styled from 'styled-components'

const StyledPageContent = styled.div`
  ${props => props.theme.fullSize};
  background-color: ${props => props.theme.colors.lYellow};
  padding-top: 28px;
  padding-bottom: 2px;

  h1 {
    margin-bottom: 9px;
  }

  h1 + div {
    padding-top: 0;
  }
`

const StyledForm = styled.div`
  padding-top: 10px;

  label {
    padding-top: 1px;
  }

  button {
    min-width: 150px;
  }
`

export default function Contact() {
  const { data, handleClick, dataError, dataSuccess } = usePageActions()

  return (
    <>
      {(data.item_title || data.item_content) && (
        <StyledPageContent>
          <PostContent 
            title={data.item_title} 
            content={data.item_content} 
          />
        </StyledPageContent>
      )}
      {data.address_section && (
        <AddressBlock content={data.address_section} />
      )}
      {data.map && (
        <MapBlock>{data.map.iframe}</MapBlock>
      )}
      {data.promo && (
        <PromoBlock content={data.promo} />
      )}
      {data.contactForm && (
        <StyledForm>
          <DefaultForm 
            content={data.contactForm} 
            handleClick={handleClick} 
            dataError={dataError}
            dataSuccess={dataSuccess}
            schema={'CONTACT'}
          />
        </StyledForm>
      )}
    </>
  );
}
