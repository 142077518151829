import React from 'react'

import BackButton from 'components/molecules/BackButton'
import SharedBlock from 'components/molecules/SharedBlock'
import Voucher from 'components/molecules/Voucher'
import Slider from 'components/organisms/Slider'
import PostContent from 'components/organisms/PostContent'
import LatestBlock from 'components/templates/LatestBlock'
import DetailSection from 'components/templates/DetailSection'
import SetingsSection from 'components/templates/SettingsSection'

import { usePageActions } from './hooks'
import { Helper } from 'helpers/simple'

export default function SinglePost({ match }) {
  const { 
    data, 
    listPage 
  } = usePageActions(match)

  return (
    <>
      {data.item_title && (
        <BackButton to={listPage}>
          {Helper.backButton(data.item_type)}
        </BackButton>
      )}
      {data.item_gallery && (
        <Slider arr={data.item_gallery} />
      )}
      {(data.item_title || data.item_subtitle || data.item_content) && (
      <PostContent 
        title={data.item_title} 
        subTitle={data.item_subtitle} 
        content={data.item_content} 
        calendar={data.item_event_date}
      />)}
      {data.more_section && (
        <DetailSection 
          content={data.more_section}
          bg={'lYellow'}
          iconVariant={'check'}
          textDiff={'5'}
        />)}
      {data.about_section && (
        <DetailSection 
          content={data.about_section}
          iconVariant={'icon'}
          borderBottom={'gray20'}
        />)}
      {data.summary_section && (
        <DetailSection 
          content={data.summary_section}
          iconVariant={'dot'}
        />)}
      {data.voucher_section && (
        <Voucher content={data.voucher_section} />
      )}
      {data.links_section && (
        <SetingsSection 
          content={data.links_section}
        />)}
      {data.share_section && (
        <SharedBlock>{data.share_section.title}</SharedBlock>
      )}
      {data.other_items_section && (
        <LatestBlock content={data.other_items_section} inline />
      )}
    </>
  );
}
