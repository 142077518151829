import React from 'react'
import PropTypes from 'prop-types'

import Typography from 'components/atoms/Typography'

import {
  StyledPromo,
  StyledLink,
  StyledContent,
  StyledTitle
} from './styles'

export default function PromoBlock({ content, ...rest }) {
  const {
    title,
    text,
    item_id,
    item_type
  } = content

  return (
    <StyledPromo {...rest}>
      <StyledLink to={`/${item_type}/${item_id}`}>
        <StyledContent>
          <StyledTitle tag={'h2'} variant={'big'}>
            {title}
          </StyledTitle>
          <Typography>{text}</Typography>
        </StyledContent>
      </StyledLink>
    </StyledPromo>
  )
}

PromoBlock.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    item_id: PropTypes.oneOfType([
      PropTypes.number, 
      PropTypes.string
    ]),
    item_type: PropTypes.string
  })
}

PromoBlock.defaultProps = {
  content: {
    title: '',
    text: '',
    item_id: '',
    item_type: ''
  }
}
