import React from 'react'

import Typography from 'components/atoms/Typography'
import LatestBlock from 'components/templates/LatestBlock'

import { usePageActions } from './hooks'

import styled from 'styled-components'

const StyledPageContent = styled.div`
  padding-top: 25px;
  padding-bottom: 2px;
`

export default function Search() {
  const { data, onPagingClick, activePage } = usePageActions()

  return (
    <StyledPageContent>
      {data.items_section && (
        <LatestBlock 
          content={{
            title: data.items_section.title,
            list: data.items_section.list
          }} 
          titleVariant={'large'}
          onPagingClick={onPagingClick}
          activePage={activePage} 
        />
      )}
      {!data.items_section 
      && (<Typography tag={'h1'}>Not found</Typography>)}
    </StyledPageContent>
  );
}
