// import routes from 'routes/routes'
import TYPES from 'store/types'

const { APP, LAYOUT, SEARCH } = TYPES

const initialState = {
  currentRoute: '',
  layout: {},
  search: {},
  activePageSearch: 1
}

const handlers = {
  [APP.REDIRECT]: (state, payload) => ({
    ...state,
    currentRoute: payload,
  }),

  [LAYOUT.GET_ALL_PENDING]: (state) => ({
    ...state,
    loading: true
  }),

  [LAYOUT.GET_ALL_SUCCESS]: (state, payload) => ({
    ...state,
    loading: false,
    layout: payload
  }),

  [LAYOUT.GET_ALL_FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),

  [SEARCH.GET_ALL_PENDING]: (state) => ({
    ...state,
    loading: true
  }),

  [SEARCH.GET_ALL_SUCCESS]: (state, payload) => ({
    ...state,
    loading: false,
    search: payload
  }),

  [SEARCH.GET_ALL_FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),

  [SEARCH.SET_ACTIVE_PAGE]: (state,  payload) => ({
    ...state,
    activePageSearch: payload
  }),

  default: (state) => state,
}

const reducerApp = (state = initialState, { type, payload }) => {
  const handler = handlers[type] || handlers.default
  return handler(state, payload)
}

export default reducerApp
