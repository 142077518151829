import { ROUTERS_PATH } from 'constants/routers'

import actionsNews from 'store/news/actions'
import { getNewsItem, getNewsContent, getNewsFilteredId, getNewsActivePage } from 'store/news/selectors'

import actionsOffers from 'store/offers/actions'
import { getOffersItem, getOffersContent, getOffersFilteredId, getOffersActivePage } from 'store/offers/selectors'

import actionsEvents from 'store/events/actions'
import { getEventsItem, getEventsContent, getEventsFilteredId, getEventsActivePage } from 'store/events/selectors'

import actionsFoods from 'store/foods/actions'
import { getFoodsItem, getFoodsContent, getFoodsFilteredId, getFoodsActivePage } from 'store/foods/selectors'

import actionsLifestyle from 'store/lifestyle/actions'
import { getLifestyleItem, getLifestyleContent, getLifestyleFilteredId, getLifestyleActivePage } from 'store/lifestyle/selectors'

const pageObj = {
  [ROUTERS_PATH.NEWS]: {
    id: getNewsFilteredId,
    content: getNewsContent,
    selector: getNewsItem,
    activePage: getNewsActivePage,
    actionId: (id) => actionsNews.newsSinglePending(id),
    actionItems: () => actionsNews.newsPending(),
    onCategoryClick: (id) => actionsNews.newsFiltered(id),
    onPagingClick: (page) => actionsNews.newsSetActivePage(page)
  },
  [ROUTERS_PATH.OFFERS]: {
    id: getOffersFilteredId,
    content: getOffersContent,
    selector: getOffersItem,
    activePage: getOffersActivePage,
    actionId: (id) => actionsOffers.offersSinglePending(id),
    actionItems: () => actionsOffers.offersPending(),
    onCategoryClick: (id) => actionsOffers.offersFiltered(id),
    onPagingClick: (page) => actionsOffers.offersSetActivePage(page)
  },
  [ROUTERS_PATH.EVENTS]: {
    id: getEventsFilteredId,
    content: getEventsContent,
    selector: getEventsItem,
    activePage: getEventsActivePage,
    actionId: (id) => actionsEvents.eventsSinglePending(id),
    actionItems: () => actionsEvents.eventsPending(),
    onCategoryClick: (id) => actionsEvents.eventsFiltered(id),
    onPagingClick: (page) => actionsEvents.eventsSetActivePage(page)
  },
  [ROUTERS_PATH.FOODS]: {
    id: getFoodsFilteredId,
    content: getFoodsContent,
    selector: getFoodsItem,
    activePage: getFoodsActivePage,
    actionId: (id) => actionsFoods.foodsSinglePending(id),
    actionItems: () => actionsFoods.foodsPending(),
    onCategoryClick: (id) => actionsFoods.foodsFiltered(id),
    onPagingClick: (page) => actionsFoods.foodsSetActivePage(page)
  },
  [ROUTERS_PATH.LIFESTYLE]: {
    id: getLifestyleFilteredId,
    content: getLifestyleContent,
    selector: getLifestyleItem,
    activePage: getLifestyleActivePage,
    actionId: (id) => actionsLifestyle.lifestyleSinglePending(id),
    actionItems: () => actionsLifestyle.lifestylePending(),
    onCategoryClick: (id) => actionsLifestyle.lifestyleFiltered(id),
    onPagingClick: (page) => actionsLifestyle.lifestyleSetActivePage(page)
  }
}

export { pageObj }