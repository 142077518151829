import { put, takeLatest } from 'redux-saga/effects'

import TYPES from 'store/types'
import actionsOffers from 'store/offers/actions'
import { http, endpoints } from 'helpers/http'

function* fetchOffersAll() {
  try {
    const response = yield http.get(`${endpoints.base}offers`)

    if (response.success) {
      yield put(actionsOffers.offersSuccess(response))
    } else {
      yield put(actionsOffers.offersFailure())
    }
  } catch(error) {
    yield put(actionsOffers.offersFailure())
  }
}

function* actionWatcherOffersAll() {
  yield takeLatest(TYPES.OFFERS.GET_ALL_PENDING, fetchOffersAll)
}

function* fetchOffersById({ id }) {
  try {
    const response = yield http.get(`${endpoints.base}offers/${id}`)

    yield put(actionsOffers.offersSingleSuccess(response))
  } catch(error) {
    yield put(actionsOffers.offersSingleFailure())
  }
}

function* actionWatcherOffersById() {
  yield takeLatest(TYPES.OFFERS.GET_BY_ID_PENDING, fetchOffersById)
}

export { actionWatcherOffersById, actionWatcherOffersAll }
