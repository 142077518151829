import React from 'react'

import Layout from 'components/templates/Layout'

import { Route, Redirect } from 'react-router-dom'
import { ROUTERS_PATH } from 'constants/routers'
import { Helper } from 'helpers/simple'
import { NAME_STORAGE } from 'constants/nameStorage'

function PublicRoute( props ) {
  const { component: Component, restricted = false, ...rest } = props
  const isAuthenticated = Helper.getItem(NAME_STORAGE.TOKEN)

  const render = props => {
    if (isAuthenticated && restricted) {
      return <Redirect to={ROUTERS_PATH.HOME} />
    }

    return  <Layout>
              <Component {...props} />
            </Layout>
  }

  return <Route {...rest} render={render} />
}

export default PublicRoute