import { put, takeLatest } from 'redux-saga/effects'

import TYPES from 'store/types'
import actionsFoods from 'store/foods/actions'
import { http, endpoints } from 'helpers/http'

function* fetchFoodsAll() {
  try {
    const response = yield http.get(`${endpoints.base}food_drinks`)

    if (response.success) {
      yield put(actionsFoods.foodsSuccess(response))
    } else {
      yield put(actionsFoods.foodsFailure())
    }
  } catch(error) {
    yield put(actionsFoods.foodsFailure())
  }
}

function* actionWatcherFoodsAll() {
  yield takeLatest(TYPES.FOODS.GET_ALL_PENDING, fetchFoodsAll)
}

function* fetchFoodsById({ id }) {
  try {
    const response = yield http.get(`${endpoints.base}food_drinks/${id}`)

    yield put(actionsFoods.foodsSingleSuccess(response))
  } catch(error) {
    yield put(actionsFoods.foodsSingleFailure())
  }
}

function* actionWatcherFoodsById() {
  yield takeLatest(TYPES.FOODS.GET_BY_ID_PENDING, fetchFoodsById)
}

export { actionWatcherFoodsById, actionWatcherFoodsAll }
