import React from 'react'

import PromoBlock from 'components/molecules/PromoBlock'
import CategoryBlock from 'components/organisms/CategoryBlock'
import LatestBlock from 'components/templates/LatestBlock'

import { usePageActions } from './hooks'

export default function Posts({ match }) {
  const { 
    data, 
    id, 
    onCategoryClick, 
    onPagingClick, 
    activePage 
  } = usePageActions(match)

  return (
    <>
      {data.categories && (
        <CategoryBlock 
          filtered={id} 
          content={data.categories} 
          onClick={onCategoryClick} 
        />
      )}
      {console.log(data)}
      {data.items_section && (
        <LatestBlock 
          content={{
            title: data.items_section.title,
            list: data.items_section.list
          }} 
          titleVariant={'large'} 
          onPagingClick={onPagingClick}
          activePage={activePage}
        >
          {data.promo && (
            <PromoBlock content={data.promo} />
          )}
        </LatestBlock>
      )}
    </>
  );
}
