import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Helper } from 'helpers/simple'
import { pageObj } from 'helpers/postActivities'

export const usePageActions = (match) => {
  const dispatch = useDispatch()
  const topicId = Helper.getTopicId(match)
  const listPage = Helper.getCategoryPage(match)
  const data = useSelector(pageObj[listPage].selector)

  useEffect(() => {
    if (pageObj[listPage]) {
      ;(() => dispatch(pageObj[listPage].actionId(topicId)))()
    }
  }, [dispatch, topicId, listPage])

  return { data, listPage }
}
