import { put, takeLatest } from 'redux-saga/effects'

import TYPES from 'store/types'
import actionsEvents from 'store/events/actions'
import { http, endpoints } from 'helpers/http'

function* fetchEventsAll() {
  try {
    const response = yield http.get(`${endpoints.base}events`)

    if (response.success) {
      yield put(actionsEvents.eventsSuccess(response))
    } else {
      yield put(actionsEvents.eventsFailure())
    }
  } catch(error) {
    yield put(actionsEvents.eventsFailure())
  }
}

function* actionWatcherEventsAll() {
  yield takeLatest(TYPES.EVENTS.GET_ALL_PENDING, fetchEventsAll)
}

function* fetchEventsById({ id }) {
  try {
    const response = yield http.get(`${endpoints.base}events/${id}`)

    yield put(actionsEvents.eventsSingleSuccess(response))
  } catch(error) {
    yield put(actionsEvents.eventsSingleFailure())
  }
}

function* actionWatcherEventsById() {
  yield takeLatest(TYPES.EVENTS.GET_BY_ID_PENDING, fetchEventsById)
}

export { actionWatcherEventsById, actionWatcherEventsAll }
