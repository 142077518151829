import React from 'react'
import PropTypes from 'prop-types'
import Component from './styles'

export default function Icon({ name, ...rest }) {
  return <Component className={`icon-${name}`} {...rest}></Component>
}

Icon.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Icon.defaultProps = {
  name: 'search',
  color: '#000',
  fontSize: '20'
}
