import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import actionsAccount from 'store/account/actions'
import { getAccountPage } from 'store/account/selectors'

export const usePageActions = () => {
  const dispatch = useDispatch()
  const data = useSelector(getAccountPage)

  useEffect(() => {
    ;(() => dispatch(actionsAccount.accountPending()))()
  }, [dispatch])

  return { data }
}

  
