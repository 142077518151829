import TYPES from 'store/types'

const actionsLifestyle = {
  lifestylePending: () => ({
    type: TYPES.LIFESTYLE.GET_ALL_PENDING,
  }),

  lifestyleSuccess: (payload) => ({
    type: TYPES.LIFESTYLE.GET_ALL_SUCCESS,
    payload,
  }),

  lifestyleFailure: () => ({
    type: TYPES.LIFESTYLE.GET_ALL_FAILURE,
  }),

  lifestyleSinglePending: (id) => ({
    type: TYPES.LIFESTYLE.GET_BY_ID_PENDING,
    id
  }),

  lifestyleSingleSuccess: (payload) => ({
    type: TYPES.LIFESTYLE.GET_BY_ID_SUCCESS,
    payload,
  }),

  lifestyleSingleFailure: () => ({
    type: TYPES.LIFESTYLE.GET_BY_ID_FAILURE,
  }),

  lifestyleFiltered: (payload) => ({
    type: TYPES.LIFESTYLE.GET_FILTERED,
    payload
  }),

  lifestyleSetActivePage: (payload) => ({
    type: TYPES.LIFESTYLE.SET_ACTIVE_PAGE,
    payload
  })
}

export default actionsLifestyle
