import styled from 'styled-components'
import LinkItem from 'components/atoms/Link'

const StyledBack = styled(LinkItem)`
  ${props => props.theme.transitionOpacity};
  ${props => props.theme.fullSize};
  background: ${props => props.theme.colors.lYellow};
  color: ${props => props.theme.colors.gray90};
  padding-top: 19px;
  padding-bottom: 14px;
  padding-left: 17px;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    color: inherit;
    opacity: 0.7;
  }

  i {
    position: relative;
    margin-right: 10px;
    top: -2px;
  }
`

export { StyledBack }