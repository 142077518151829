import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import actionsContact from 'store/contact/actions'
import { getContactPage, getContactSuccess, getContactError } from 'store/contact/selectors'

export const usePageActions = () => {
  const dispatch = useDispatch()
  const data = useSelector(getContactPage)
  const dataError = useSelector(getContactError)
  const dataSuccess = useSelector(getContactSuccess)

  useEffect(() => {
    ;(() => dispatch(actionsContact.contactPending()))()
  }, [dispatch])

  const resetFormBlock = (resetForm) => {
    dispatch(actionsContact.contactPending())
    resetForm({})
  }

  const handleClick = (values, { resetForm }) => {
    const arr = []
    for (let key in values) {
      arr.push(key + '=' + values[key])
    }

    dispatch(actionsContact.contactSentPending(arr.join('&')))
    resetFormBlock(resetForm)
  }

  return { data, handleClick, dataError, dataSuccess }
}

  
