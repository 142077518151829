import styled from 'styled-components'

import Typography from 'components/atoms/Typography'
import LinkItem from 'components/atoms/Link'

const StyledPromo = styled.div`
  text-align: center;
  margin-bottom: ${props => props.mb || 20}px;

  p {
    margin-bottom: 0;
  }
`
const StyledLink = styled(LinkItem)`
  background: ${props => props.theme.colors.pink};
  ${props => props.theme.transitionOpacity};
  ${props => props.theme.fullSize};
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;

  &:hover {
    opacity: 0.7;
  }
`

const StyledContent = styled.div`
  color: ${props => props.theme.colors.white};
  max-width: 300px;
  margin: 0 auto;
`

const StyledTitle = styled(Typography)`
  font-size: ${props => props.theme.fontSize.big};
  margin-bottom: 5px;
`

export {
  StyledPromo,
  StyledLink,
  StyledContent,
  StyledTitle
}