import TYPES from 'store/types'

const actionsHome = {
  homePending: () => ({
    type: TYPES.HOME.GET_PAGE_PENDING,
  }),

  homeSuccess: (payload) => ({
    type: TYPES.HOME.GET_PAGE_SUCCESS,
    payload,
  }),

  homeFailure: () => ({
    type: TYPES.HOME.GET_PAGE_FAILURE,
  }),
}

export default actionsHome
