import get from 'lodash/get'
import { createSelector } from 'reselect'

export const getOffersItems = createSelector(
  (state) => get(state, ['offers', 'content']) || [],
  (content) => content,
)

export const getOffersItem = createSelector(
  (state) => get(state, ['offers', 'currentItem']) || [],
  (currentItem) => currentItem,
)

export const getOffersFilteredId = createSelector(
  (state) => get(state, ['offers', 'filtered']) || [],
  (filtered) => filtered,
)

export const getOffersContent = createSelector(
  (state) => get(state, ['offers', 'content']) || {},
  (state) => get(state, ['offers', 'filtered']) || [],
  (state) => get(state, ['offers', 'content', 'items_section', 'list']) || [],
  (content, filtered, list) => {
    return {
      ...content,
      items_section: {
        ...content.items_section,
        list: filtered.length ? list.filter(el => filtered.includes(el.item_category_id)) : list
      }
    }
  },
)

export const getOffersActivePage = createSelector(
  (state) => get(state, ['offers', 'activePage']) || [],
  (activePage) => activePage,
)