import styled from 'styled-components'

const StyledLink = styled.div`
  color: ${props => props.theme.colors.gray90};
  padding-top: 5px;
  text-align: center;
  border-top: 5px solid transparent;
  width: 50px;

  .inline & {
    display: flex;
    width: auto;
    border: none;
    padding-top: 8px;
  }

  .active:not(.not-active) &,
  &.active {
    border-top: 5px solid ${props => props.theme.colors.yellow};
  }

  &.menu.menu {
    padding-top: 10px; 

    span {
      padding-top: 10px;
    }
  }

  .line & {
    span {
      line-height: 1;
      letter-spacing: 0.06em;
      padding-top: 7px;
    }
  }

  span {
    display: block;
    padding-top: 5px;
  }
`

export { StyledLink }