import styled from 'styled-components'

const StyledMenu = styled.div`
  ${props => props.theme.fixedBlock};
  ${props => props.theme.fixedBlock.botPos};
  ${props => props.theme.container};
  border-top: 1px solid ${props => props.theme.colors.gray10};
  background: ${props => props.theme.colors.white};
  padding: 0 15px 4px;
`

const StyledDropMenu = styled.div`
  ${props => props.theme.fullAbsolute};
  ${props => props.theme.container};
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  overflow: auto;
  padding-top: 20px;
  top: 59px;
  bottom: 69px;
`

const StyledLogo = styled.div`
  ${props => props.theme.fullSize};
  border-bottom: 1px solid ${props => props.theme.colors.gray10};
  padding-bottom: 30px;
  margin-bottom: 18px;

  a {
    display: inline-block;
    vertical-align: top;
    height: 36px;
  }
`

const StyledDropTop = styled.div`
  padding: 8px 0;
`
const StyledDropBottom = styled.div`
  padding: 30px 0 20px; 
`


export { StyledMenu, StyledDropMenu, StyledLogo, StyledDropTop, StyledDropBottom };