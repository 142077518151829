import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/atoms/Icon'
import Typography from 'components/atoms/Typography'

import { StyledBack } from './styles'

export default function BackButton({ children, ...rest }) {
  return (
    <StyledBack {...rest}>
      <Icon name={'spiral-arrow'} color={'gray90'} fontSize={'28'} />
      <Typography tag={'span'}>Back to {children}</Typography>
    </StyledBack>
  )
}

BackButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node, 
    PropTypes.string
  ]).isRequired,
}
