import React from "react";
import PropTypes from 'prop-types'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Icon from 'components/atoms/Icon'
import LinkItem from 'components/atoms/Link'
import Typography from 'components/atoms/Typography'

import {
  StyledSlider,
  StyledSlydeContent,
  StyledImage,
  StyledTextBlock,
  StyledTextBottom
} from './styles'

SwiperCore.use([Pagination])

export default function HeroSlider({ arr }) {
  return (
    <StyledSlider>
      <Swiper
        pagination={{ clickable: true }}
      >
        {arr.map((item) =>(
          <SwiperSlide key={item.key}>
            <StyledSlydeContent>
              <StyledImage image={item.img.src}/>
              <StyledTextBlock>
                <Typography tag={'h1'}>{item.title}</Typography>
                <StyledTextBottom>
                  <Typography>{item.text}</Typography>
                  <LinkItem to={`/${item.link.item_type}/${item.link.item_id}`}>
                    <Icon name={'spiral-arrow'} color={'yellow'} fontSize={'70'} />
                  </LinkItem>
                </StyledTextBottom>
              </StyledTextBlock>
            </StyledSlydeContent>
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledSlider>
  )
}

HeroSlider.propTypes = {
  arr: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    src: PropTypes.string
  })).isRequired,
}

