import TYPES from 'store/types'

const actionsContact = {
  contactPending: () => ({
    type: TYPES.CONTACT.GET_PAGE_PENDING,
  }),

  contactSuccess: (payload) => ({
    type: TYPES.CONTACT.GET_PAGE_SUCCESS,
    payload,
  }),

  contactFailure: () => ({
    type: TYPES.CONTACT.GET_PAGE_FAILURE,
  }),

  contactSentPending: (data) => ({
    type: TYPES.CONTACT.POST_PAGE_PENDING,
    data
  }),

  contactSentSuccess: (payload) => ({
    type: TYPES.CONTACT.POST_PAGE_SUCCESS,
    payload,
  }),

  contactSentFailure: (payload) => ({
    type: TYPES.CONTACT.POST_PAGE_FAILURE,
    payload
  }),
}

export default actionsContact
