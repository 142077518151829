import styled from 'styled-components'

const StyledSlider = styled.div`
  ${props => props.theme.fullSize};
  overflow: hidden;
  margin-bottom: 20px;
  padding: 0;

  .swiper-slide {
    img {
      width: 100%;
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);

    span {
      ${props => props.theme.sliderkDots};
      ${props => props.theme.sliderDotButton};
    }
  }
`

export { StyledSlider };