import styled from 'styled-components'
import Typography from 'components/atoms/Typography'

const StyledBlock = styled.div`
  ${props => props.theme.fullSize}
  background: ${props => props.theme.colors[props.bg]};
  padding-top: 20px;
  padding-bottom: 20px;

  & + & {
    padding-top: 12px;
  }
`

const StyledHeading = styled.div`
  padding-bottom: 14px;
`

const StyledTitle = styled(Typography)`
  margin-bottom: 10px;

  &:only-child {
    text-align: center;
  }
`

const StyledText = styled(Typography)`
  margin-bottom: 20px;
`

export { StyledBlock, StyledHeading, StyledTitle, StyledText };