import React from 'react'

import Image from 'components/atoms/Image'
import PostContent from 'components/organisms/PostContent'
import AccordionBlock from 'components/organisms/AccodrionBlock'

import { usePageActions } from './hooks'

import styled from 'styled-components'

const StyledImage = styled.div`
  ${props => props.theme.fullSize};
  padding: 0;
  margin-bottom: 20px;
`

export default function Faq() {
  const { data } = usePageActions()

  return (
    <>
      <StyledImage>
        <Image params={data.item_image} full />
      </StyledImage>
      {(data.item_title || data.item_subtitle || data.item_content) && (
      <PostContent 
        title={data.item_title} 
        subTitle={data.item_subtitle} 
        content={data.item_content} 
      />)}
      {data.item_list && <AccordionBlock list={data.item_list} />}
    </>
  );
}
