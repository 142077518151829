import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import createSageMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { logger } from 'redux-logger'

import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

const persistConfig = { // configuration object for redux-persist
  key: 'root',
  storage, // define which storage to use
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSageMiddleware()

const middleware = [sagaMiddleware, logger]

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware)),
)

const  persistor = persistStore(store);

sagaMiddleware.run(rootSaga)

export {store, persistor}
