import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Typography from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'

import {
  StyledItem,
  StyledOpener,
  StyledOpenerText,
  StyledSlide
} from './styles'

export default function AccordionItem({ item }) {
  const { opener, text } = item
  const [ isOpen, setOpen ] = useState()

  return (
    <StyledItem>
      <StyledOpener 
        onClick={() => setOpen(!isOpen)}
        className={isOpen && 'is-open'}
      >
        <StyledOpenerText>{opener}</StyledOpenerText>
        <Icon name={'arrow-down'} fontSize={'17'} />
      </StyledOpener>
      {isOpen && <StyledSlide>
        <Typography>{text}</Typography>
      </StyledSlide>}
    </StyledItem>
  )
}

AccordionItem.propTypes = {
  item: PropTypes.shape({
    opener: PropTypes.string,
    text: PropTypes.string,
  })
}

AccordionItem.defaultProps = {
  item: {
    opener: '',
    text: ''
  }
}