import styled from 'styled-components'
import LinkItem from 'components/atoms/Link'

const StyledForm = styled.div`
`

const StyledField = styled.div`
  margin-bottom: 20px;
`

const StyledMessage = styled.div`
  color: ${props => props.theme.colors.pink};
  font-size: ${props => props.theme.fontSize.mini};
  text-align: center;
  padding-bottom: 20px;

  &.success {
    color: ${props => props.theme.colors.yellow};
  }
`

const StyledButtonWrap = styled.div`
  ${props => props.theme.dFlexAlignCenter};
`

const StyledLinkButton = styled(LinkItem)`
  ${props => props.theme.buttons.gray};
  margin-left: 15px;
`

const StyledRemembering = styled.div`
  padding-top: 22px;
`

const StyledForgot = styled.div`
  padding-top: 19px;
`

const StyledForgotLink = styled(LinkItem)`
  color: ${props => props.theme.colors.gray90};
  margin-left: 5px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

export { StyledForm, StyledField, StyledMessage, StyledButtonWrap, StyledLinkButton, StyledRemembering, StyledForgot, StyledForgotLink };