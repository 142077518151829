import styled from 'styled-components'
import Typography from 'components/atoms/Typography'

const StyledContent = styled.div`
  padding-top: 20px;
  margin-bottom: 20px;
`

const StyledTitle = styled(Typography)`
  font-weight: normal;
  margin-bottom: 22px;
`

const StyledCategory = styled.div`
  ${props => props.theme.fullSize};
  border-top: 1px solid ${props => props.theme.colors.yellow};
  border-bottom: 1px solid ${props => props.theme.colors.yellow};
  overflow: hidden;
  padding: 14px 0;
  height: 64px;
`

const StylesList = styled.ul`
  ${props => props.theme.dFlexAlignCenter};
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledItem = styled.li`
  padding: 0 8px;

  &:first-child {
    padding-left: 18px;
  }

  &:last-child {
    padding-right: 18px;
  }
`

export { StyledTitle, StyledContent, StyledCategory, StylesList, StyledItem };