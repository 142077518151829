import styled from 'styled-components'

const StyledContent = styled.div`
  ${props => props.theme.fullContainer};
  ${props => props.theme.dFlexBetween};
  align-items: stretch;
  margin-bottom: 15px;

  & > div {
    width: 50%;
  }
`

const StyledLeftBlock = styled.div`
  ${props => props.theme.dFlexBetween};
  flex-direction: column;
`

const StyledRightBlock = styled.div`
  display: flex;
`

export { StyledContent, StyledLeftBlock, StyledRightBlock };