import styled from 'styled-components'

const StyledContent = styled.div`
  padding-bottom: 8px;

  h1 {
    line-height: 1.2;
    margin: 0 0 12px;
  }
`

const StyledCalendar = styled.div`
  ${props => props.theme.dFlexAlignCenter};

  i {
    margin-right: 10px;
    color: ${props => props.theme.colors.yellow};
  }

  h2 {
    margin: 0;
  }
`

const StyledText = styled.div`
  padding-top: 15px;
`

export { StyledContent, StyledCalendar, StyledText };