import styled from 'styled-components'

const StyledMap = styled.div`
  ${props => props.theme.fullContainer};
  height: 211px;
  width: calc(100% + 36px);
`

const StyledIframe = styled.div`
  width: 100%;

  iframe {
    height: 211px;
    width: 100%;
  }
`

export { StyledMap, StyledIframe }