import styled from 'styled-components'

const StyledList = styled.ul`
  &.inline {
    margin-right: -15px;
    margin-left: -15px;
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const StyledPost = styled.li`
  width: 153px;
  min-width: 153px;
  padding: 0 10px;

  &.line {
    border-bottom: 1px solid ${props => props.theme.colors.gray10};
    ${props => props.theme.fullSize};
    min-width: calc(100% + 36px);

    &:first-child,
    &:last-child {
      min-width: calc(100% + 36px);
      padding-left: 18px;
      padding-right: 18px;
    }

    & + .line {
      .item-line {
        padding-top: 19px;
      }
    }

    .item-line {
      padding: 0 0 18px;
    }

    img {
      width: 122px;
      min-width: 122px;
    }
  }

  &:first-child {
    padding-left: 15px;
    min-width: 158px;
  }

  &:last-child {
    padding-right: 15px;
    min-width: 158px;
  }
`

const WrapItem = styled.div`
  &.item-line {
    width: 100%;

    a {
      display: flex;
    }

    div {
      margin-bottom: 0;

      &:first-child {
        padding-right: 25px;
      }
    }
  }
`

const StyledPromo = styled.li`
  margin-top: 29px;
  margin-bottom: 28px;
`

export { StyledList, StyledPost, WrapItem, StyledPromo };