import TYPES from 'store/types'

const actionsAccount = {
  accountPending: () => ({
    type: TYPES.ACCOUNT.GET_PAGE_PENDING,
  }),

  accountSuccess: (payload) => ({
    type: TYPES.ACCOUNT.GET_PAGE_SUCCESS,
    payload,
  }),

  accountFailure: () => ({
    type: TYPES.ACCOUNT.GET_PAGE_FAILURE,
  }),
}

export default actionsAccount
