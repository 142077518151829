import styled from 'styled-components'

const StyledItem = styled.div`
  font-size: ${props => props.theme.fontSize.micro};
  text-transform: uppercase;
  max-width: 50px;

  &.inline {
    position: relative;
    font-size: ${props => props.theme.fontSize.base};
    max-width: 100%;
    text-transform: none;

    .active {
      & > div {
        color: ${props => props.theme.colors.yellow};
      }
    }

    i {
      position: absolute;
      font-size: 20px;
      left: 0;
      top: 15px;

      &.icon-mail {
        font-size: 14px;
        left: 2px;
      }

      &.icon-food-drinks {
        top: 12px;
      }

      &.icon-news,
      &.icon-faqs,
      &.icon-logout {
        font-size: 16px;
        left: 2px;
      }
    }

    span {
      padding-left: 32px; 
      max-width: none;
    }
  }
`

export { StyledItem }