const Helper = {
  isRefLink: (href) => href.indexOf('http') > -1,
  
  getTopicId: (match) => match.params.topicId,
  getCategoryPage: (match) => match.url.split(`/${Helper.getTopicId(match)}`)[0],
  
  phoneNormalize: (phone) => phone.replace(/[\s\t]/g, ''),
  
  scrollTop: () => window.scrollTo(0, 0),
  
  hasObject: (obj) => !!Object.keys(obj).length,

  capitalize: (str) => str.charAt(0).toUpperCase() + str.slice(1, str.length),
  backButton: (str) => Helper.capitalize(str).indexOf('ifestyle') > -1 ? 'Wellbeing & Lifestyle' : Helper.capitalize(str).replace('_', ' & '),

  overflowBody: (isOpen) => document.body.style.overflow = isOpen ? 'hidden' : '',

  getLocation: () => window.location,
  push: (page) => Helper.getLocation().href = page,

  setItem: (key, val, isLS) => isLS ? localStorage.setItem(key, JSON.stringify(val)) : sessionStorage.setItem(key, JSON.stringify(val)),
  getItem: (key) => JSON.parse(sessionStorage.getItem(key)) || JSON.parse(localStorage.getItem(key)),
  removeItem: (key, isLS) => isLS ? localStorage.removeItem(key) : sessionStorage.removeItem(key),
  clearItem: (key) => {
    sessionStorage.removeItem(key)
    localStorage.removeItem(key)
  },

  getInitalObj: (list, add) => list.reduce((obj, item) => {
    obj[item.name] = ''
    if (add && Object.keys(add).length) {
      obj[add.name] = false
    }
    return obj
  }, {})
}

export { Helper }