import React from 'react'
import PropTypes from 'prop-types'

import LatestItem from 'components/molecules/LatestItem'

import {
  StyledList,
  StyledPost,
  WrapItem,
  StyledPromo
} from './styles'

export default function LatestList({ 
  arr, 
  inline,
  addBlock, 
  countPerPage,
  activePage,
  ...rest 
}) {
  const postClass = !inline && 'line'
  const itemClass = !inline && 'item-line'
  const count = (activePage - 1) * countPerPage
  const len = arr.length + arr.length % 2
  const halhPage = (len < countPerPage ? len : countPerPage) / 2

  return (
    <StyledList 
      className={inline ? 'inline' : ''} 
      {...rest} 
    >
      {arr.slice(count, count + halhPage).map((item, i) => (
        <StyledPost 
          className={postClass}
          key={item.key}
        >
          <WrapItem className={itemClass}>
            <LatestItem item={item} />
          </WrapItem>  
        </StyledPost>
      ))}
      {addBlock && activePage === 1 && <StyledPromo>{addBlock}</StyledPromo>}
      {arr.slice(count + halhPage, count + countPerPage).map((item, i) => (
        <StyledPost 
          className={postClass}
          key={item.key}
        >
          <WrapItem className={itemClass}>
            <LatestItem item={item} />
          </WrapItem>  
        </StyledPost>
      ))}
    </StyledList>
  )
}

LatestList.propTypes = {
  addBlock: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  arr: PropTypes.arrayOf(PropTypes.object).isRequired,
  inline: PropTypes.bool,
  countPerPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activePage: PropTypes.number
}

LatestList.defaultProps = {
  countPerPage: 8,
  activePage: 1
}
