import React from 'react'
import PropTypes from 'prop-types'

import Image from 'components/atoms/Image'
import Typography from 'components/atoms/Typography'

import { StyledVoucher, StyledHeading, StyledBox, StyledText } from './styles'

export default function Voucher({ content, ...rest }) {
  const {
    code,
    image,
    subtitle,
    title
  } = content

  return (
    <StyledVoucher {...rest}>
      <StyledHeading>
        <Typography tag={'h2'} variant={'big'}>{title}</Typography>
      </StyledHeading>
      <StyledBox>
        <StyledText>
          <Typography tag={'strong'} variant={'small'}>
            {subtitle}
          </Typography>
          <Typography tag={'h1'}>{code}</Typography>
        </StyledText>
        <Image params={image} />
      </StyledBox>
    </StyledVoucher>
  )
}

Voucher.propTypes = {
  content: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    code: PropTypes.string
  })
}

Voucher.defaultProps = {
  content: {
    image: {
      src: '',
      alt: ''
    },
    title: '',
    subtitle: '',
    code: ''
  }
}
