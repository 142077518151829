import styled from 'styled-components'

const ComponentBar = styled.div`
  position: relative;
  margin-top: 3px;
`

const IconSearch = styled.button`
  background: none;
  border: none;
  padding: 0;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: 2px;
`

export { ComponentBar, IconSearch }