export const ROUTERS_PATH = {
  LOGIN: "/login",
  HOME: "/",
  SEARCH: "/search",
  NEWS: "/news",
  EVENTS: "/events",
  OFFERS: "/offers",
  FOODS: "/food_drinks",
  CONTACT: "/contact",
  ACCOUNT: "/account",
  FAQ: "/faq",
  LIFESTYLE: "/lifestyle",
  NOT_FOUND: "/not-found"
}