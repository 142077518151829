import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom'

import PrivateRoute from 'hoc/PrivateRoute'
import PublicRoute from 'hoc/PublicRoute'

import ScrollToTop from 'helpers/scrollToTop'

import { routesObj } from 'routes/config'

function Routes() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
            {routesObj.map((item, key) => (
              item.public ? (
                <PublicRoute 
                  component={item.component} 
                  path={item.path} 
                  key={key} 
                  exact 
                />
              ) : (
                <PrivateRoute 
                  component={item.component} 
                  path={item.path} 
                  key={key} 
                  exact 
                />
              )
            ))}
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default Routes;
