import styled from 'styled-components'

import LinkItem from 'components/atoms/Link'
import Typography from 'components/atoms/Typography'

const StyledItem = styled(LinkItem)`
  ${props => props.theme.transitionOpacity};
  text-decoration: none;
  
  &:hover {
    opacity: 0.7
  }
`

const StyledImage = styled.div`
  margin-bottom: 10px;
`

const StyledItemBody = styled.div`
  color: ${props => props.theme.colors.gray90};

  h3 {
    margin-bottom: 5px;

    .item-line & {
      margin-bottom: 3px;
    }

    & + p {
      padding-top: 0;
    }
  }

  p {
    margin-bottom: 8px;
    padding-top: 2px;
  }
`

const StyledCategory = styled(Typography)`
  font-family: ${props => props.theme.fontFamily.add};
  padding-top: 3px;

  .line & {
    color: ${props => props.theme.colors.pink};
    padding-top: 0;
  }
`

const StyledDate = styled.div`
  letter-spacing: -0.017em;
`

export {
  StyledItem,
  StyledItemBody,
  StyledImage,
  StyledCategory,
  StyledDate
}