import TYPES from 'store/types'

const actionsNews = {
  newsPending: () => ({
    type: TYPES.NEWS.GET_ALL_PENDING,
  }),

  newsSuccess: (payload) => ({
    type: TYPES.NEWS.GET_ALL_SUCCESS,
    payload,
  }),

  newsFailure: () => ({
    type: TYPES.NEWS.GET_ALL_FAILURE,
  }),

  newsSinglePending: (id) => ({
    type: TYPES.NEWS.GET_BY_ID_PENDING,
    id
  }),

  newsSingleSuccess: (payload) => ({
    type: TYPES.NEWS.GET_BY_ID_SUCCESS,
    payload,
  }),

  newsSingleFailure: () => ({
    type: TYPES.NEWS.GET_BY_ID_FAILURE,
  }),

  newsFiltered: (payload) => ({
    type: TYPES.NEWS.GET_FILTERED,
    payload
  }),

  newsSetActivePage: (payload) => ({
    type: TYPES.NEWS.SET_ACTIVE_PAGE,
    payload
  })
}

export default actionsNews
