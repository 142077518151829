import styled from 'styled-components'
import LinkItem from 'components/atoms/Link'

const StyledTile = styled(LinkItem)`
  background-color: ${props => props.theme.colors.yellow};
  color: ${props => props.theme.colors.gray90};
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  min-height: 47vw;
  width: 100%;

  &.full {
    background: url(${props => props.image});
    color: ${props => props.theme.colors.white};
    background-size: cover;
    background-position: center;
    position: relative;

    &:before {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
      ${props => props.theme.fullAbsolute};
      content: '';
    }
  }

  &:not(.full) {
    img {
      object-fit: cover;
      max-width: 97px;
      margin-bottom: 20px;
    }
  }

  h2 {
    position: relative;
  }

  img {
    width: 100%;
  }
`

export { StyledTile }