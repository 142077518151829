import { useDispatch, useSelector } from 'react-redux'

import { Helper} from 'helpers/simple'
import { getSearch, getSearchActivePage } from 'store/app/selectors'

import actionsApp from 'store/app/actions'

export const usePageActions = () => {
  const dispatch = useDispatch()
  const data = useSelector(getSearch)
  const activePage = useSelector(getSearchActivePage)

  const onPagingClick = (page) => {
    dispatch(actionsApp.searchSetActivePage(page))
    Helper.scrollTop()
  }

  return { data, onPagingClick, activePage }
}
