import get from 'lodash/get'
import { createSelector } from 'reselect'

export const getContactPage = createSelector(
  (state) => get(state, ['contact', 'content']) || [],
  (content) => content,
)

export const getContactSuccess = createSelector(
  (state) => get(state, ['contact', 'success']) || [],
  (success) => success,
)

export const getContactError = createSelector(
  (state) => get(state, ['contact', 'errors']) || [],
  (errors) => errors,
)