const TYPES = {
  APP: {
    REDIRECT: 'APP/REDIRECT',
  },

  LAYOUT: {
    GET_ALL_PENDING: 'LAYOUT/GET_ALL_PENDING',
    GET_ALL_SUCCESS: 'LAYOUT/GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'LAYOUT/GET_ALL_FAILURE',
  },

  SEARCH: {
    GET_ALL_PENDING: 'SEARCH/GET_ALL_PENDING',
    GET_ALL_SUCCESS: 'SEARCH/GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'SEARCH/GET_ALL_FAILURE',
    SET_ACTIVE_PAGE: 'SEARCH/SET_ACTIVE_PAGE'
  },

  NEWS: {
    GET_ALL_PENDING: 'NEWS/GET_ALL_PENDING',
    GET_ALL_SUCCESS: 'NEWS/GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'NEWS/GET_ALL_FAILURE',
    GET_BY_ID_PENDING: 'NEWS/GET_BY_ID_PENDING',
    GET_BY_ID_SUCCESS: 'NEWS/GET_BY_ID_SUCCESS',
    GET_BY_ID_FAILURE: 'NEWS/GET_BY_ID_FAILURE',
    GET_FILTERED: 'NEWS/GET_FILTERED',
    SET_ACTIVE_PAGE: 'NEWS/SET_ACTIVE_PAGE'
  },

  OFFERS: {
    GET_ALL_PENDING: 'OFFERS/GET_ALL_PENDING',
    GET_ALL_SUCCESS: 'OFFERS/GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'OFFERS/GET_ALL_FAILURE',
    GET_BY_ID_PENDING: 'OFFERS/GET_BY_ID_PENDING',
    GET_BY_ID_SUCCESS: 'OFFERS/GET_BY_ID_SUCCESS',
    GET_BY_ID_FAILURE: 'OFFERS/GET_BY_ID_FAILURE',
    GET_FILTERED: 'OFFERS/GET_FILTERED',
    SET_ACTIVE_PAGE: 'OFFERS/SET_ACTIVE_PAGE'
  },

  EVENTS: {
    GET_ALL_PENDING: 'EVENTS/GET_ALL_PENDING',
    GET_ALL_SUCCESS: 'EVENTS/GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'EVENTS/GET_ALL_FAILURE',
    GET_BY_ID_PENDING: 'EVENTS/GET_BY_ID_PENDING',
    GET_BY_ID_SUCCESS: 'EVENTS/GET_BY_ID_SUCCESS',
    GET_BY_ID_FAILURE: 'EVENTS/GET_BY_ID_FAILURE',
    GET_FILTERED: 'EVENTS/GET_FILTERED',
    SET_ACTIVE_PAGE: 'EVENTS/SET_ACTIVE_PAGE'
  },

  FOODS: {
    GET_ALL_PENDING: 'FOODS/GET_ALL_PENDING',
    GET_ALL_SUCCESS: 'FOODS/GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'FOODS/GET_ALL_FAILURE',
    GET_BY_ID_PENDING: 'FOODS/GET_BY_ID_PENDING',
    GET_BY_ID_SUCCESS: 'FOODS/GET_BY_ID_SUCCESS',
    GET_BY_ID_FAILURE: 'FOODS/GET_BY_ID_FAILURE',
    GET_FILTERED: 'FOODS/GET_FILTERED',
    SET_ACTIVE_PAGE: 'FOODS/SET_ACTIVE_PAGE'
  },

  LIFESTYLE: {
    GET_ALL_PENDING: 'LIFESTYLE/GET_ALL_PENDING',
    GET_ALL_SUCCESS: 'LIFESTYLE/GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'LIFESTYLE/GET_ALL_FAILURE',
    GET_BY_ID_PENDING: 'LIFESTYLE/GET_BY_ID_PENDING',
    GET_BY_ID_SUCCESS: 'LIFESTYLE/GET_BY_ID_SUCCESS',
    GET_BY_ID_FAILURE: 'LIFESTYLE/GET_BY_ID_FAILURE',
    GET_FILTERED: 'LIFESTYLE/GET_FILTERED',
    SET_ACTIVE_PAGE: 'LIFESTYLE/SET_ACTIVE_PAGE'
  },

  ACCOUNT: {
    GET_PAGE_PENDING: 'ACCOUNT/GET_PAGE_PENDING',
    GET_PAGE_SUCCESS: 'ACCOUNT/GET_PAGE_SUCCESS',
    GET_PAGE_FAILURE: 'ACCOUNT/GET_PAGE_FAILURE'
  },

  CONTACT: {
    GET_PAGE_PENDING: 'CONTACT/GET_PAGE_PENDING',
    GET_PAGE_SUCCESS: 'CONTACT/GET_PAGE_SUCCESS',
    GET_PAGE_FAILURE: 'CONTACT/GET_PAGE_FAILURE',
    POST_PAGE_PENDING: 'CONTACT/POST_PAGE_PENDING',
    POST_PAGE_SUCCESS: 'CONTACT/POST_PAGE_SUCCESS',
    POST_PAGE_FAILURE: 'CONTACT/POST_PAGE_FAILURE'
  },

  FAQ: {
    GET_PAGE_PENDING: 'FAQ/GET_PAGE_PENDING',
    GET_PAGE_SUCCESS: 'FAQ/GET_PAGE_SUCCESS',
    GET_PAGE_FAILURE: 'FAQ/GET_PAGE_FAILURE'
  },

  HOME: {
    GET_PAGE_PENDING: 'HOME/GET_PAGE_PENDING',
    GET_PAGE_SUCCESS: 'HOME/GET_PAGE_SUCCESS',
    GET_PAGE_FAILURE: 'HOME/GET_PAGE_FAILURE'
  },

  LOGIN: {
    GET_PAGE_PENDING: 'LOGIN/GET_PAGE_PENDING',
    GET_PAGE_SUCCESS: 'LOGIN/GET_PAGE_SUCCESS',
    GET_PAGE_FAILURE: 'LOGIN/GET_PAGE_FAILURE',
    POST_PAGE_PENDING: 'LOGIN/POST_PAGE_PENDING',
    POST_PAGE_SUCCESS: 'LOGIN/POST_PAGE_SUCCESS',
    POST_PAGE_FAILURE: 'LOGIN/POST_PAGE_FAILURE'
  },
}

export default TYPES
