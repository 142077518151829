import styled from 'styled-components'
import LinkItem from 'components/atoms/Link'

const StyledHeader = styled.div`
  ${props => props.theme.fixedBlock};
  ${props => props.theme.fixedBlock.topPos};
  ${props => props.theme.dFlexBetween};
  background: ${props => props.theme.colors.brown};
  ${props => props.theme.container};
  padding: 10px 16px 11px 15px;
`

const StyledLogo = styled(LinkItem)`
  margin-left: 3px;
  min-width: 40px;
`

const StyledSearch = styled.div`
  margin-left: 3px;
`

const StyledLogin = styled.div`
  position: relative;
  top: 2px;
  min-width: 30px;
  text-align: right;
`

export { 
  StyledHeader, 
  StyledLogo, 
  StyledSearch, 
  StyledLogin 
};